<template>
  <div class="flex flex-col gap-y-2">
    <note-appointment></note-appointment>
    <symptomes></symptomes>
    <exploration-examen></exploration-examen>
    <diagnostic-soins></diagnostic-soins>
    <note-confidentielle></note-confidentielle>
  </div>
</template>

<script>
import noteAppointment from "./layouts/noteAppointment.vue";
import symptomes from "./layouts/symptomes.vue";
import explorationExamen from "./layouts/explorationExamen.vue";
import diagnosticSoins from "./layouts/diagnosticSoins.vue";
import noteConfidentielle from "./layouts/noteConfidentielle.vue";

export default {
  components: {
    noteAppointment,
    symptomes,
    explorationExamen,
    diagnosticSoins,
    noteConfidentielle
  }
};
</script>

<style></style>
