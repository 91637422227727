<template>
  <div class="p-5">
    <div class="flex justify-end">
      <span class="cursor-pointer" @click="closeModal">
        <img src="/svg/x.svg" />
      </span>
    </div>

    <div class="flex flex-col items-center justify-center gap-y-8">
      <img src="/svg/onboarding/file-text.svg" class="h-24 w-24" />
      <h1 class="text-xl text-black font-EffraR">Compte rendu</h1>
      <p
        class="text-center font-EffraR text-base leading-normal"
        style="color: #515151;"
      >
        Retrouvez un récapitulatif général de la consultation
      </p>
      <dok-button
        size="md"
        custom-class="px-10 font-EffraM"
        @click.native="closeModal"
        >J'ai compris</dok-button
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  methods: {
    closeModal() {
      let getLocalStorage = JSON.parse(
        localStorage.getItem("onbording_consultation")
      );

      getLocalStorage.compteRendu = true;

      localStorage.setItem(
        "onbording_consultation",
        JSON.stringify(getLocalStorage)
      );

      EventBus.$emit("CLOSR_ONBORDING_MODAL");
    }
  }
};
</script>
