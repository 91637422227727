<template>
  <div class="p-5">
    <div class="flex justify-end">
      <span class="cursor-pointer" @click="closeModal">
        <img src="/svg/x.svg" />
      </span>
    </div>
    <div class="flex flex-col items-center justify-center gap-y-8">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 16 16"
        class="text-dokBlue-ultra h-24 w-24"
      >
        <g clip-path="url(#clip0)">
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M14.293 7.367l-6.127 6.126c-.75.75-1.768 1.172-2.83 1.172-1.061 0-2.08-.421-2.83-1.172-.75-.75-1.172-1.768-1.172-2.83 0-1.061.422-2.08 1.172-2.83l6.127-6.126c.5-.5 1.179-.782 1.887-.782.707 0 1.386.281 1.886.782.5.5.782 1.179.782 1.886 0 .708-.281 1.387-.782 1.887l-6.133 6.127c-.25.25-.59.39-.943.39-.354 0-.694-.14-.944-.39s-.39-.59-.39-.944.14-.693.39-.943l5.66-5.653"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0H16V16H0z" />
          </clipPath>
        </defs>
      </svg>

      <h1 class="text-xl text-black font-EffraR">Ordonnances et certificats</h1>
      <p
        class="text-center font-EffraR text-base leading-normal"
        style="color: #515151;"
      >
        Détaillez le déroulement de la visite médicale pour que votre patient et
        vous gardiez une traçabilité de la consultation
      </p>
      <dok-button
        size="md"
        custom-class="px-10 font-EffraM"
        @click.native="closeModal"
        >J'ai compris</dok-button
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  methods: {
    closeModal() {
      let getLocalStorage = JSON.parse(
        localStorage.getItem("onbording_consultation")
      );

      getLocalStorage.ordonnaces = true;

      localStorage.setItem(
        "onbording_consultation",
        JSON.stringify(getLocalStorage)
      );

      EventBus.$emit("CLOSR_ONBORDING_MODAL");
    }
  }
};
</script>
