<template>
  <div>
    <div
      class="bg-green-100 text-green-500 text-base font-EffraR w-full flex items-center gap-x-2 h-12 px-2 rounded-t-lg"
    >
      <div
        class="h-[26px] w-[26px] bg-green-500 text-white overflow-hidden rounded-full flex items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-eye"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
          <circle cx="12" cy="12" r="3"></circle>
        </svg>
      </div>
      Cette note sera partagée avec le patient
    </div>
    <div
      class="bg-white px-8 rounded-b-lg py-5 flex flex-col gap-y-6 w-full border-t-0"
      style="border: solid 2px #ededed;"
    >
      <div class="flex flex-row gap-x-1">
        <img src="/svg/consultation/flask-line.svg" />
        <h1 class="text-xl font-EffraR text-black">
          Exploration / Examen clinique
        </h1>
      </div>

      <hr style="border: 1px solid #eaeaea;" />

      <template v-if="!loader">
        <div class="flex flex-col gap-y-1">
          <label for="spechText" class="text-base text-black font-EffraR">
            Notes</label
          >
          <speach-textarea
            id="spechText"
            :content.sync="note"
            holder="Insert text here or dictate by clicking on the button below"
          ></speach-textarea>

          <p class="font-EffraR text-green-700 animate-pulse" v-if="saveNote">
            Enregistrement ...
          </p>
        </div>

        <section id="files" class="flex flex-col gap-y-3">
          <h1 class="text-base text-black font-EffraR">Fichiers</h1>

          <template v-if="files && files.length">
            <div
              class="p-5 h-20 rounded-cu grid gap-x-10 grid-cols-5 items-start"
              style="border: solid 1px #ededed;"
              v-for="(file, index) in files"
              :key="index"
            >
              <span
                class="bg-red-200 text-red-700 font-EffraM rounded-cu text-center py-1 px-3 flex items-center justify-center"
              >
                {{ file.mimeType }}
              </span>

              <h1
                class="text-black text-lg font-EffraM whitespace-pre-line break-words truncate"
              >
                {{ file.name }}
              </h1>

              <div class="flex items-center gap-x-1">
                <!-- <span class="font-EffraR text-base text-black">Uploaded</span> -->
                <span class="font-EffraM text-base text-black">{{
                  file.created_at | moment("DD/MM/YYYY")
                }}</span>
              </div>

              <div>
                <span class="hidden">EMPTY</span>
              </div>

              <div class="flex flex-row gap-x-10 justify-end">
                <dok-button
                  bg="none"
                  custom-class="font-EffraM text-black flex items-center gap-x-2"
                  @click.native="$downloadDocumentByID(file._id)"
                >
                  <img src="/svg/consultation/eye_black.svg" />
                  Aperçu
                </dok-button>
                <dok-button
                  bg="none"
                  custom-class="font-EffraM text-red-500 flex items-center gap-x-2"
                  @click.native="onDeleteFile(file._id)"
                >
                  <img src="/svg/consultation/trash.svg" />
                  Supprimer
                </dok-button>
              </div>
            </div>
          </template>

          <!--------------------------DRAG DROP NEW FILES----------------------------->
          <upload-document
            :files.sync="computedFiles"
            :start-upload="startUploadFiles"
            :status="uploadStatus"
            :refId="`exploration-upload`"
            id="uploadExploration"
          ></upload-document>
        </section>
      </template>

      <template v-if="loader">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto; display: block; shape-rendering: auto"
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
          </circle>
          <circle cx="70" cy="50" fill="#2e6bff" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="0s"
            ></animate>
          </circle>
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
            <animate
              attributeName="fill-opacity"
              values="0;0;1;1"
              calcMode="discrete"
              keyTimes="0;0.499;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            ></animate>
          </circle>
        </svg>
      </template>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import speachTextarea from "@/views/global-components/spechToTextarea";
import uploadDocument from "../../globalComponents/upload.vue";
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      loader: true,
      note: "",
      saveNote: false,
      files: [],
      uploadFiles: null,
      uploadStatus: "REDAY_TO_UPLOAD"
    };
  },
  watch: {
    note: _.debounce(function(newVal, oldVal) {
      if (oldVal === null) {
        return;
      }

      return this.onSaveNote();
    }, 400)
  },
  computed: {
    computedFiles: {
      set(value) {
        if (value && value.length) {
          this.uploadFiles = value[0];
          EventBus.$emit("start_upload_files_consultation");
        }
      },
      get() {
        return this.uploadFiles;
      }
    }
  },
  async created() {
    await this.getExplorationExamen();
  },
  methods: {
    async onSaveNote() {
      this.saveNote = true;
      await this.$store.dispatch("consultation/UPDATE_EXPLORATION_EXAMEN", {
        id: this.$route.params.id,
        note: this.note,
        onData: ({ ok, data, message }) => {
          this.saveNote = false;
        }
      });
    },
    async getExplorationExamen() {
      await this.$store.dispatch("consultation/GET_EXPLORATION_EXAMEN", {
        id: this.$route.params.id,
        onData: async ({ ok, data }) => {
          if (ok) {
            this.note = data.note;

            // Get File Examen
            const { ok: fileOk } = await this.getAllFiles("examen");
            if (fileOk) {
              this.loader = false;
            }
          }
        }
      });
    },
    async getAllFiles(type) {
      await this.$store.dispatch("consultation/GET_RAPPORT_FILES", {
        type,
        consultationId: this.$route.params.id,
        onData: ({ ok, data }) => {
          if (ok) {
            this.files = data;
          }
        }
      });

      return { ok: true };
    },
    async onDeleteFile(fileID) {
      EventBus.$emit("flashDok", {
        defualtTime: 30000,
        type: "delete",
        title: "Suppression fichier",
        message: "Êtes-vous sûrs de vouloir supprimer ce fichier ",
        successmessage: "Fichier supprimé avec succès",
        confirm: async () => {
          this.$vs.loading();
          await this.$store.dispatch("consultation/REMOVE_FILE", {
            documentId: fileID,
            consultationId: this.$route.params.id,
            onData: async data => {
              const { ok: fileOk } = await this.getAllFiles("symptomes");
              if (fileOk) {
                this.$vs.loading.close();
              }
            }
          });
        },
        cancel: callback => {
          return callback();
        }
      });
    },
    startUploadFiles() {
      this.$vs.loading();
      this.uploadStatus = "START_UPLOAD";
      this.$store.dispatch("consultation/UPLOAD_RAPPORT_FILE", {
        type: "examen",
        consultationId: this.$route.params.id,
        file: this.uploadFiles,
        onData: async ({ ok, data, message }) => {
          if (ok) {
            this.uploadStatus = "UPLOAD_SUCCESS";
            this.uploadFiles = null;
            const { ok: fileOk } = await this.getAllFiles("examen");
            if (fileOk) {
              this.$vs.loading.close();
            }
          }
        }
      });
    }
  },
  components: { speachTextarea, uploadDocument }
};
</script>

<style></style>
