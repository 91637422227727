<template>
  <section id="imageries-block">
    <div
      class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-10"
      :class="hasOpen ? 'min-h-20' : ' h-20'"
    >
      <!-----------------------  Header  --------------------->
      <div class="flex justify-between items-center w-full">
        <div class="flex-1 flex flex-row gap-x-1">
          <img src="/svg/consultation/body-scan.svg" />
          <h1 class="text-xl font-EffraR text-black">Imagerie médicale</h1>
        </div>

        <button
          class="bg-transparent border-0 p-0 m-0 cursor-pointer"
          @click="hasOpen = !hasOpen"
        >
          <img
            :src="
              hasOpen
                ? '/svg/consultation/chevron-up.svg'
                : '/svg/consultation/chevron-down.svg'
            "
          />
        </button>
      </div>

      <hr v-if="hasOpen" class="border border-solid border-gray-200" />

      <!---------------------  Favories  ---------------------->
      <section
        id="favoris-medical-imagery"
        class="bg-bgBlueTrans p-6 flex flex-col gap-y-4"
        v-if="hasOpen"
      >
        <h1 class="font-EffraR text-base text-black leading-normal">Favoris</h1>
        <div>
          <div class="grid grid-cols-1 gap-y-2 pl-5">
            <template v-for="(favoriItem, index) in listFavorites">
              <div
                v-if="favoriItem.imagerie"
                :key="'select-' + index"
                class="rounded p-3 flex flex-row justify-between"
                style="border: solid 1px #eaeaea"
              >
                <div class="flex flex-row gap-x-2 items-center">
                  <div>
                    <div
                      class="
                        h-8
                        w-8
                        rounded-full
                        cursor-pointer
                        hover:bg-dokBlue-lighter
                        relative
                        flex
                        items-center
                        justify-center
                      "
                      :class="
                        onExitsFavorite(favoriItem.imagerie._id) &&
                        'bg-dokBlue-lighter'
                      "
                      style="border: solid 1px #eaeaea"
                      @click="
                        onExitsListSelected(favoriItem.imagerie)
                          ? unsetItem(favoriItem.imagerie)
                          : setItem(favoriItem.imagerie, null)
                      "
                    >
                      <i
                        class="fas fa-check text-dokBlue-ultra text-xl"
                        v-if="onExitsListSelected(favoriItem.imagerie)"
                      ></i>
                    </div>
                  </div>
                  <span class="font-EffraM text-dokBlue-ultra">{{
                    favoriItem.imagerie.description
                  }}</span>
                </div>
                <div>
                  <span
                    class="
                      p-2
                      bg-dokBlue-lighter
                      rounded
                      font-EffraM
                      text-sm
                      capitalize
                    "
                    style="color: #11597d"
                    >{{ favoriItem.imagerie.category }}</span
                  >
                  <dok-button
                    bg="white"
                    @click.native="deleteImagerieFavorite(favoriItem._id)"
                  >
                    <img src="/svg/consultation/star.svg" />
                  </dok-button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </section>

      <!----------------  liste imageries  -------------------->
      <section
        class="
          flex flex-col
          gap-y-6
          border-0 border-t border-solid border-gray-400
          pt-2
        "
        v-if="hasOpen"
      >
        <template v-if="!loading">
          <div class="grid grid-cols-1 lg:grid-cols-6 gap-x-3 gap-y-3">
            <div
              class="dok-box-square"
              v-for="(category, index) in listImageries"
              :key="'btImg-' + index"
              :class="category._id == activeCat ? 'active' : ''"
              @click="selectCategory(category._id)"
            >
              {{ category._id }}
            </div>
          </div>
          <h2 class="capitalize">{{ nameCategories[activeCat] }}</h2>
          <div class="grid gap-4 grid-cols-2">
            <div
              class="rounded p-6 flex flex-col gap-y-4"
              style="border: solid 1px #eaeaea"
              v-for="(subcat, index) in activeCatList"
              :key="'subCatImg-' + index"
            >
              <div class="flex justify-between items-center">
                <h1 class="font-EffraM text-black text-lg capitalize">
                  {{ subcat }}
                </h1>
                <dok-button
                  bg="white"
                  custom-class="flex items-center gap-x-2"
                  @click.native="openModal(subcat)"
                  >Voir plus <img src="/svg/consultation/trailing.svg" />
                </dok-button>
              </div>
            </div>
          </div>
        </template>
      </section>

      <!----------------  selected items  ---------------------->
      <section
        id="selected-imageries"
        class="flex flex-col gap-y-3"
        v-if="hasOpen"
      >
        <h1 class="font-EffraR text-base text-black leading-normal">
          Sélectionnés
        </h1>
        <div v-for="(catBoj, keyCat) in selectedItems" :key="keyCat">
          <div v-if="selectedItems[keyCat].length > 0">
            <h2 class="font-EffraL uppercase py-2">{{ keyCat }}</h2>
            <div class="grid grid-cols-1 gap-y-2 pl-5">
              <div
                v-for="(selectedItem, index) in selectedItems[keyCat]"
                :key="'select-' + index"
                class="rounded p-3 flex flex-row justify-between"
                style="border: solid 1px #eaeaea"
              >
                <div class="flex flex-row gap-x-2 items-center">
                  <div>
                    <div
                      class="
                        h-8
                        w-8
                        rounded-full
                        cursor-pointer
                        hover:bg-dokBlue-lighter
                        relative
                        flex
                        items-center
                        justify-center
                      "
                      :class="
                        onExistsActImagerie(
                          selectedItem._id,
                          selectedItem.name
                        ) && 'bg-dokBlue-lighter'
                      "
                      style="border: solid 1px #eaeaea"
                      @click="unsetItem(selectedItem)"
                    >
                      <i class="fas fa-check text-dokBlue-ultra text-xl"></i>
                    </div>
                  </div>
                  <span class="font-EffraM text-dokBlue-ultra">{{
                    selectedItem.description
                  }}</span>
                </div>
                <div>
                  <span
                    class="
                      p-2
                      bg-dokBlue-lighter
                      rounded
                      font-EffraM
                      text-sm
                      capitalize
                    "
                    style="color: #11597d"
                    >{{ selectedItem.category }}</span
                  >
                </div>
              </div>
            </div>
            <!----- Note ------>
            <div class="flex flex-col gap-y-1 pt-5 pl-5">
              <label for="spechText" class="text-base text-black font-EffraR">
                Notes (Optionnel)</label
              >
              <speach-textarea
                :id="'spechText-' + keyCat"
                :content.sync="notes[keyCat]"
                holder="Insert text here or dictate by clicking on the button below"
              ></speach-textarea>

              <p
                class="font-EffraR text-green-700 animate-pulse"
                v-if="saveNote"
              >
                Enregistrement ...
              </p>
            </div>
          </div>
        </div>
      </section>

      <!----------------------  Actions  ----------------------->
      <div
        class="flex flex-col lg:flex-row lg:justify-end gap-x-6 gap-y-4 pt-10"
        v-if="hasOpen"
      >
        <dok-button
          size="lg"
          bg="bluelighter"
          custom-class="px-3 flex items-center gap-x-2"
          @click.native="$openPrescription(idPrescription, '?type=IMAGERY&')"
        >
          <img src="/svg/consultation/eye.svg" /> Aperçu
        </dok-button>

        <dok-button
          size="lg"
          custom-class="hidden px-3 flex items-center gap-x-2"
          @click.native="onGeneration"
        >
          <img src="/svg/consultation/sent.svg" /> Générer une ordonnance
        </dok-button>
        <dok-button
          size="lg"
          custom-class="px-3 flex items-center gap-x-2"
          @click.native="onMultipleGeneration"
        >
          <img src="/svg/consultation/sent.svg" /> Générer ordonnances par
          catégorie
        </dok-button>
      </div>
    </div>
    <!----------------------------  Modal  --------------------->
    <modal
      :isActive="modal.modalIs"
      :exitModal="() => {}"
      :hiddenHeader="true"
      :hasNoScroll="true"
      :min-width="400"
    >
      <div class="p-5 flex flex-col gap-y-4" v-if="activeSubCat !== null">
        <div class="flex justify-between items-center">
          <h1 class="text-2xl font-EffraR text-black capitalize">
            {{ activeCat + "   /   " + this.activeSubCat }}
          </h1>
          <button
            class="bg-transparent border-0 m-0 p-0 cursor-pointer"
            @click="
              () => {
                modal.modalIs = false;
                modal.termSearch = '';
              }
            "
          >
            <img src="/svg/consultation/x.svg" />
          </button>
        </div>
        <dok-input
          size="lg"
          d-placeholder="Recherchez..."
          :d-model.sync="modal.termSearch"
        >
          <template v-slot:leftIcon>
            <img src="/svg/search.svg" alt="Search..." />
          </template>
        </dok-input>
        <div class="max-h-64 overflow-y-scroll flex flex-col gap-y-2">
          <div
            class="p-5 rounded flex items-center gap-x-2"
            :class="subItem.willBeHidden ? 'will-be-hidden' : ''"
            style="border: solid 1px #eaeaea"
            v-for="(subItem, subIndex) in listImageriesSubcatSearch"
            :key="subIndex"
            v-if="!subItem.selected"
          >
            <div>
              <div
                class="
                  h-8
                  w-8
                  rounded-full
                  cursor-pointer
                  hover:bg-dokBlue-lighter
                  relative
                  flex
                  items-center
                  justify-center
                "
                :class="subItem.selected ? 'bg-dokBlue-lighter' : ''"
                style="border: solid 1px #eaeaea"
                @click="setItem(subItem, subIndex)"
              >
                <i
                  class="fas fa-check text-dokBlue-ultra text-xl"
                  v-if="subItem.selected || subItem.willBeHidden"
                ></i>
              </div>
            </div>
            <label
              :for="`asfd-${subIndex}`"
              class="font-EffraR text-base cursor-pointer"
            >
              {{ subItem.description }}<br />
              <span class="font-EffraR text-xs cursor-pointer x">{{
                subItem.name
              }}</span>
            </label>
            <dok-button
              bg="white"
              @click.native="
                onExitsFavorite(subItem._id, subItem.category)
                  ? deleteImagerieFavorite(
                      onExitsFavorite(subItem._id, subItem.category)
                    )
                  : setAsFavorite(subItem)
              "
            >
              <img
                :src="
                  onExitsFavorite(subItem._id, subItem.category)
                    ? '/svg/consultation/star.svg'
                    : '/svg/consultation/lg_star_gray.svg'
                "
              />
            </dok-button>
          </div>
        </div>
      </div>
    </modal>
  </section>
</template>
<script>
  import _ from "lodash";
  import speachTextarea from "@/views/global-components/spechToTextarea";
  import modal from "@/views/global-components/modal";

  export default {
    components: { speachTextarea, modal },
    data() {
      return {
        loading: true,
        hasOpen: false,
        saveNote: false,
        listImageries: [],
        activeCatList: [],
        listImageriesSubcat: [],
        listImageriesSubcatSearch: [],
        listFavorites: [],
        idPrescription: null,
        activeCat: null,
        activeSubCat: null,
        mapImageriesSubcat: {},
        selectedItems: {
          IRM: [],
          RX: [],
          echo: [],
          interventionnel: [],
          scanner: [],
          seno: [],
        },
        notes: {
          IRM: "",
          RX: "",
          echo: "",
          interventionnel: "",
          scanner: "",
          seno: "",
        },
        modal: {
          listIndex: null,
          modalIs: false,
          termSearch: null,
        },
        nameCategories: {
          IRM: "Imagerie par Résonance Magnétique",
          RX: "radiographie conventionnelle",
          echo: "échographie & doppler",
          interventionnel: "radiologie interventionnelle",
          scanner: "scanner",
          seno: "senologie",
        },
      };
    },
    watch: {
      "modal.termSearch"(newVal, oldVal) {
        if (newVal.length > 1) {
          this.getFiltred(newVal);
        } else {
          this.getFiltred(null);
        }
      },
      "notes.RX": _.debounce(function (newVal, oldVal) {
        if (oldVal === null) {
          return;
        }
        return this.onSaveNote(newVal, "RX");
      }, 400),
      "notes.echo": _.debounce(function (newVal, oldVal) {
        if (oldVal === null) {
          return;
        }
        return this.onSaveNote(newVal, "echo");
      }, 400),
      "notes.seno": _.debounce(function (newVal, oldVal) {
        if (oldVal === null) {
          return;
        }
        return this.onSaveNote(newVal, "seno");
      }, 400),
      "notes.IRM": _.debounce(function (newVal, oldVal) {
        if (oldVal === null) {
          return;
        }
        return this.onSaveNote(newVal, "IRM");
      }, 400),
      "notes.scanner": _.debounce(function (newVal, oldVal) {
        if (oldVal === null) {
          return;
        }
        return this.onSaveNote(newVal, "scanner");
      }, 400),
      "notes.interventionnel": _.debounce(function (newVal, oldVal) {
        if (oldVal === null) {
          return;
        }
        return this.onSaveNote(newVal, "interventionnel");
      }, 400),
    },
    mounted() {
      this.imageriesList();
      this.getListImagerieFavorite();
    },
    methods: {
      selectCategory(cat) {
        this.activeCat = cat;
        for (let key in this.listImageries) {
          if (this.listImageries[key]._id == cat) {
            this.activeCatList = this.listImageries[key].list;
            break;
          }
        }
      },
      getFiltred(newVal) {
        this.listImageriesSubcatSearch = _.filter(
          this.mapImageriesSubcat[this.activeCat][this.activeSubCat],
          function (item) {
            let found = !item.selected;
            if (newVal) {
              found &=
                item.description.toLowerCase().indexOf(newVal.toLowerCase()) >= 0;
            }
            return found;
          }
        );
      },
      onExistsActImagerie(item) {
        for (let i in this.selectedItems[item.category]) {
          if (item._id == this.selectedItems[item.category][i]._id) {
            this.selectedItems[item.category].splice(i, 1);
            break;
          }
        }
        return false;
      },
      onExitsListSelected(selectedItem) {
        let i = _.find(this.selectedItems[selectedItem.category], (item) => {
          return selectedItem._id === item._id;
        });
        return i ? i : false;
      },
      onExitsFavorite(subItem_id) {
        let i = _.find(this.listFavorite, (item) => {
          return item.imagerie._id === subItem_id;
        });
        return i ? i : false;
      },
      async imageriesList(level) {
        await this.$store.dispatch("consultation/GET_STANDARD_IMAGERIES", {
          level: level,
          onData: ({ ok, data }) => {
            if (ok) {
              this.listImageries = data;
              this.imageriesListSelected();
              if (this.listImageries.length > 0)
                this.selectCategory(this.listImageries[0]._id);
            }
          },
        });
        this.loading = false;
      },
      async imageriesListSelected() {
        await this.$store.dispatch("consultation/GET_PRESCRIPTIONS", {
          id: this.$route.params.id,
          category: "IMAGERY",
          onData: ({ ok, data }) => {
            if (ok) {
              this.idPrescription = data._id;
              this.note = data.note;
              for (let i in data.notes) {
                this.notes[data.notes[i].category] = data.notes[i].note;
              }
              this.$store.dispatch("consultation/GET_PRESCRIPTION_ITEMS", {
                id: data._id,
                onData: ({ ok: itemOk, data: itemData }) => {
                  if (itemOk) {
                    for (let item of itemData) {
                      if (item.refId)
                        this.selectedItems[String(item.refId.category)].push(
                          item.refId
                        );
                    }
                  }
                  this.$forceUpdate();
                  this.loading = false;
                },
              });
            }
          },
        });
      },
      async getListImagerieFavorite() {
        await this.$store.dispatch("consultation/GET_MY_FAVORITE_IMAGERIE", {
          onData: ({ data, ok }) => {
            if (ok) {
              this.listFavorites = data;
            }
            this.loader = false;
            this.$vs.loading.close();
          },
        });
      },
      async openModal(subcat) {
        this.activeSubCat = subcat;
        this.$nextTick(() => {
          this.modal.modalIs = true;
        });
        if (
          this.activeCat in this.mapImageriesSubcat &&
          subcat in this.mapImageriesSubcat[this.activeCat]
        ) {
          return;
        }
        await this.$store.dispatch("consultation/GET_STANDARD_IMAGERIES", {
          level: 2,
          cat: this.activeCat,
          subcat: subcat,
          onData: ({ ok, data }) => {
            if (ok) {
              this.listImageriesSubcat = data;
              this.listImageriesSubcatSearch = data;
              this.mapImageriesSubcat[this.activeCat] = {};
              this.mapImageriesSubcat[this.activeCat][subcat] = data;
            }
          },
        });
      },
      async onSaveNote(note, category) {
        this.saveNote = true;
        this.$store.dispatch("consultation/SAVE_PRESCRIPTION_MULTI_NOTES", {
          note: note,
          category: "IMAGERY",
          subcategory: category,
          id: this.idPrescription,
          onData: ({ ok, message }) => {
            if (ok) {
              this.saveNote = false;
            }
          },
        });
      },
      async setItem(item, indxSearch) {
        if (this.onExitsListSelected(item)) return;
        item.selected = true;
        this.$forceUpdate();
        // Set item in section selected items
        let name = item.category;
        if (item.name) {
          name += " / " + item.name;
        }
        if (item.description) {
          name += " / " + item.description;
        }
        await this.$store.dispatch("consultation/ADD_IMAGE_TO_PRESCRIPTION", {
          id: this.idPrescription,
          data: {
            name: name,
            refId: item._id,
          },
          onData: ({ data: dataRes, ok: okRes }) => {
            if (okRes) {
              // item.selected = true;
              // item.willBeHidden = false
              this.activeCat = item.category;
              if (!(this.activeCat in this.mapImageriesSubcat)) {
                this.mapImageriesSubcat[this.activeCat] = {};
              }
              this.activeSubCat = item.subcategory;
              if (
                !(this.activeSubCat in this.mapImageriesSubcat[this.activeCat])
              ) {
                this.mapImageriesSubcat[this.activeCat][this.activeSubCat] = [];
              }
              for (let i in this.mapImageriesSubcat[this.activeCat][
                this.activeSubCat
              ]) {
                if (
                  item._id ==
                  this.mapImageriesSubcat[this.activeCat][this.activeSubCat]._id
                ) {
                  this.mapImageriesSubcat[this.activeCat][
                    this.activeSubCat
                  ].selected = true;
                }
              }
              // un if
              let found = false;
              for (let i in this.selectedItems[item.category]) {
                if (item._id == this.selectedItems[item.category][i]._id) {
                  found = true;
                  break;
                }
              }
              if (!found) {
                this.selectedItems[this.activeCat].push(item);
              }
              this.$forceUpdate();
            }
            this.$vs.loading.close();
          },
        });
      },
      async unsetItem(item) {
        for (let i in this.selectedItems[item.category]) {
          if (item._id == this.selectedItems[item.category][i]._id) {
            this.selectedItems[item.category].splice(i, 1);
            break;
          }
        }
        this.activeCat = item.category;
        if (!(this.activeCat in this.mapImageriesSubcat)) {
          this.mapImageriesSubcat[this.activeCat] = {};
        }
        this.activeSubCat = item.subcategory;
        if (!(this.activeSubCat in this.mapImageriesSubcat[this.activeCat])) {
          this.mapImageriesSubcat[this.activeCat][this.activeSubCat] = [];
        }
        // set as unselected from map
        for (let i in this.mapImageriesSubcat[this.activeCat][
          this.activeSubCat
        ]) {
          if (
            item._id ==
            this.mapImageriesSubcat[this.activeCat][this.activeSubCat]._id
          ) {
            this.mapImageriesSubcat[this.activeCat][
              this.activeSubCat
            ].selected = false;
          }
        }
        await this.$store.dispatch(
          "consultation/REMOVE_IMAGE_FROM_PRESCRIPTION",
          {
            id: this.idPrescription,
            refId: item._id,
            onData: ({ data: dataRes, ok: okRes }) => {},
          }
        );
      },
      async deleteImagerieFavorite(favoriItemId) {
        await this.$store.dispatch("consultation/REMOVE_IMAGERIE_ROM_FAVORITE", {
          favoriteId: favoriItemId,
          onData: ({ ok, data }) => {
            this.getListImagerieFavorite();
          },
        });
      },
      async setAsFavorite(item) {
        this.$vs.loading();
        await this.$store.dispatch("consultation/ADD_IMAGERIE_TO_FAVORITE", {
          data: {
            imagerie: item._id,
          },
          onData: ({ ok, data }) => {
            this.getListImagerieFavorite();
          },
        });
      },
      async onGeneration() {
        this.$vs.loading();
        await this.$store.dispatch("consultation/GENERATION_DOCUMENT", {
          data: {
            id: this.idPrescription,
            type: "IMAGERY",
          },
          onData: ({ ok, data, message }) => {
            this.$vs.notify({
              time: 4000,
              text: message,
              color: ok ? "success" : "danger",
            });
            this.$vs.loading.close();
          },
        });
      },
      async onMultipleGeneration() {
        this.$vs.loading();
        await this.$store.dispatch("consultation/GENERATION_DOCUMENT", {
          data: {
            id: this.idPrescription,
            type: "IMAGERY",
            typeAnalyses: Object.keys(this.selectedItems).join(","),
          },
          onData: ({ ok, data, message }) => {
            this.$vs.notify({
              time: 4000,
              text: message,
              color: ok ? "success" : "danger",
            });
            this.$vs.loading.close();
          },
        });
      },
    },
  };
</script>
<style>
.will-be-hidden {
  background-color: #aae;
}
</style>
