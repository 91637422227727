<template>
  <div
    class="relative p-1 rounded-cu hover:bg-greyx cursor-pointer"
    :class="drop && 'bg-dokBlue-lighter'"
  >
    <div @click="drop = !drop">
      <span
        class="font-EffraR text-sm"
        style="color: #767676;"
        :class="drop && 'text-dokBlue-ultra'"
      >
        {{ label || "--" }}
      </span>
      <div
        v-if="!value"
        class="text-lg font-EffraR text-black"
        :class="drop && 'text-dokBlue-ultra'"
      >
        {{ dPlaceholder || "--" }}
      </div>
      <div
        v-if="value"
        class="text-lg font-EffraR text-black"
        :class="drop && 'text-dokBlue-ultra'"
      >
        {{ value }}
      </div>
    </div>

    <div
      class="box-drop"
      style="box-shadow: 0 8px 52px 0 rgba(0, 0, 0, 0.08);"
      v-if="drop"
    >
      <template v-if="dList">
        <div class="flex flex-col">
          <div
            class="py-2 px-1 font-EffraR hover:bg-grayxx"
            v-for="(name, index) in dList"
            :key="index"
            @click="setItem(hasValue === 'index' ? index : name[hasValue])"
          >
            {{ getValue(name) }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String | Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    dPlaceholder: {
      type: String,
      required: false
    },
    dList: {
      type: Array | Object,
      required: false
    },
    labelSelect: {
      type: String | Number,
      required: false
    },
    valueSelect: {
      type: String | Number,
      required: false
    }
  },
  computed: {
    hasValue() {
      if (this.valueSelect) {
        return this.valueSelect;
      }

      return "index";
    }
  },
  data() {
    return {
      drop: false
    };
  },
  methods: {
    getValue(key) {
      if (this.labelSelect) {
        return key[this.labelSelect];
      }

      return key;
    },
    setItem(name) {
      event.preventDefault();
      if (name) {
        this.$emit("update:value", name);
        this.drop = false;
      }
    }
  }
};
</script>

<style lang="scss">
.box-drop {
  @apply bg-white rounded-cu absolute inset-x-0 z-1 max-h-48 overflow-y-scroll;
}
</style>
