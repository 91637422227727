<template>
  <div>
    <div
      :id="refId"
      class="border-2 border-dashed border-borderUpload h-6 py-10 rounded-cu cursor-pointer relative flex px-8"
      :class="
        status !== 'START_UPLOAD'
          ? 'items-center justify-center'
          : 'items-center'
      "
    >
      <template v-if="status !== 'START_UPLOAD'">
        <div class="flex flex-row items-center gap-x-2">
          <img src="/svg/consultation/file-plus.svg" />
          <h1 class="font-EffraR text-lg text-navgray">
            {{ label }}
          </h1>
        </div>
        <input
          :id="`fileInput-${refId}`"
          type="file"
          class="absolute inset-0 w-full opacity-0 cursor-pointer"
          @change="onDrop"
        />
      </template>

      <!--------------------------START  UPLOAD  FILES------------------------------>
      <template v-else>
        <div class="flex flex-col gap-y-2 w-full">
          <div class="flex flex-col gap-y-1 w-full">
            <div class="flex justify-between items-center w-full">
              <div class="flex flex-row items-center gap-x-2 flex-1">
                <span id="fileName" class="font-EffraM text-black text-base">
                  {{ files }}
                </span>
                <span class="font-EffraR text-labelGray text-base"
                  >Uploading...</span
                >
              </div>
            </div>
            <div class="bg-dokBlue-lighter rounded-full w-full h-2">
              <div
                class="bg-dokBlue-ultra rounded-full h-2"
                style="width: 5px;"
              ></div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: {
    label: {
      type: String,
      required: false,
      default: "Cliquez pour ajouter des fichiers"
    },
    refId: {
      type: String,
      required: true
    },
    startUpload: {
      type: Function,
      required: true
    },
    files: {
      type: Array | FileList,
      required: true
    },
    status: {
      type: String,
      required: true,
      default: "REDAY_TO_UPLOAD"
    }
  },
  methods: {
    async onDrop() {
      event.preventDefault();
      let querySelector = document.getElementById(this.refId);
      let filesInput = document.getElementById(`fileInput-${this.refId}`);

      if (querySelector.classList.contains("bg-dokBlue-lighter")) {
        querySelector.classList.remove("bg-dokBlue-lighter");
      }

      this.$emit("update:files", filesInput.files);

      this.$nextTick(() => {
        this.startUpload();
      });
    }
  }
};
</script>

<style></style>
