<template>
  <div>
    <div
      class="bg-dokBlue-lighter text-dokBlue-ultra text-base font-EffraR w-full flex items-center gap-x-2 h-12 px-2 rounded-t-lg"
    >
      <img src="/svg/consultation/eyebgblue.svg" />
      Cette note est uniquement visible par le praticien qui l'a rédigé
    </div>
    <div
      class="bg-white px-8 rounded-b-lg py-5 flex flex-col gap-y-6 w-full border-2 border-t-0 border-solid border-otgray"
    >
      <div class="flex flex-row gap-x-1">
        <img src="/svg/consultation/confidential-note.svg" />
        <h1 class="text-xl font-EffraR text-black">
          Note confidentielle
        </h1>
      </div>

      <hr style="border: 1px solid #eaeaea;" />

      <template v-if="!loader">
        <div class="flex flex-col gap-y-1">
          <label for="spechText" class="text-base text-black font-EffraR">
            Notes</label
          >
          <speach-textarea
            id="spechText"
            :content.sync="note"
            holder="Insert text here or dictate by clicking on the button below"
          ></speach-textarea>

          <p class="font-EffraR text-green-700 animate-pulse" v-if="saveNote">
            Enregistrement ...
          </p>
        </div>
      </template>

      <template v-if="loader">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto; display: block; shape-rendering: auto"
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
          </circle>
          <circle cx="70" cy="50" fill="#2e6bff" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="0s"
            ></animate>
          </circle>
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
            <animate
              attributeName="fill-opacity"
              values="0;0;1;1"
              calcMode="discrete"
              keyTimes="0;0.499;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            ></animate>
          </circle>
        </svg>
      </template>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import speachTextarea from "@/views/global-components/spechToTextarea";

export default {
  data() {
    return {
      note: "",
      saveNote: false,
      loader: true
    };
  },
  watch: {
    note: _.debounce(function(newVal, oldVal) {
      if (oldVal === null) {
        return;
      }

      return this.onSaveNote();
    }, 400)
  },
  created() {
    this.onNoteConfidentielle();
  },
  methods: {
    async onSaveNote() {
      this.saveNote = true;
      await this.$store.dispatch("consultation/UPDATE_NOTE_CONFIDENTIELLE", {
        id: this.$route.params.id,
        note: this.note,
        onData: ({ ok, data, message }) => {
          this.saveNote = false;
        }
      });
    },
    onNoteConfidentielle() {
      this.$store.dispatch("consultation/GET_NOTE_CONFIDENTIELLE", {
        id: this.$route.params.id,
        onData: ({ ok, data }) => {
          if (ok) {
            this.note = data.note;
            this.loader = false;
          }
        }
      });
    }
  },
  components: { speachTextarea }
};
</script>

<style></style>
