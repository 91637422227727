<template>
  <section id="Medicament">
    <div
      class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-10"
      :class="hasOpen ? 'min-h-20' : ' h-20'"
    >
      <!-- ------------------------------------------------ Header ---------------------------------------------------->
      <div class="flex justify-between items-center w-full">
        <div class="flex-1 flex flex-row gap-x-1">
          <img src="/svg/consultation/medicine-bottle-line.svg" />
          <h1 class="text-xl font-EffraR text-black">Médicaments</h1>
        </div>

        <button
          class="bg-transparent border-0 p-0 m-0 cursor-pointer"
          @click="hasOpen = !hasOpen"
        >
          <img
            :src="
              hasOpen
                ? '/svg/consultation/chevron-up.svg'
                : '/svg/consultation/chevron-down.svg'
            "
          />
        </button>
      </div>

      <template v-if="!loader">
        <hr v-if="hasOpen" class="border border-solid border-gray-200" />
        <!---------- Content Medicaments -->
        <div class="flex flex-col gap-y-3" v-if="hasOpen">
          <!-- Create New Medicament -->
          <create-medicament
            :prescription-id="prescriptionID"
            class="p-2"
          ></create-medicament>
          <!-- End Create Medicament -->

          <!-- List Medicamanet -->
          <list-medicament :list="listMedicaments"></list-medicament>
          <!-- End List Medicament -->
          <div class="flex flex-col gap-y-1">
            <label for="spechText" class="text-base text-black font-EffraR">
              Notes (Optionnel)</label
            >
            <speach-textarea
              id="spechText"
              :content.sync="note"
              holder="Insert text here or dictate by clicking on the button below"
            ></speach-textarea>

            <p class="font-EffraR text-green-700 animate-pulse" v-if="saveNote">
              Enregistrement ...
            </p>
          </div>

          <div class="flex flex-col lg:flex-row lg:justify-end gap-y-4 gap-x-6">
            <dok-button
              size="lg"
              bg="bluelighter"
              custom-class="px-3 flex items-center gap-x-2"
              @click.native="$openPrescription(prescriptionID)"
            >
              <img src="/svg/consultation/eye.svg" /> Aperçu
            </dok-button>

            <dok-button
              size="lg"
              custom-class="px-3 flex items-center gap-x-2"
              @click.native="onGeneration"
            >
              <img src="/svg/consultation/sent.svg" /> Génerer l'ordonnance
            </dok-button>
          </div>
        </div>
      </template>
      <template v-if="loader">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto; display: block; shape-rendering: auto"
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
          </circle>
          <circle cx="70" cy="50" fill="#2e6bff" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="0s"
            ></animate>
          </circle>
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
            <animate
              attributeName="fill-opacity"
              values="0;0;1;1"
              calcMode="discrete"
              keyTimes="0;0.499;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            ></animate>
          </circle>
        </svg>
      </template>
    </div>
  </section>
</template>

<script>
  import _ from "lodash";
  import { EventBus } from "@/event-bus/index.js";
  import createMedicament from "./layouts/create.vue";
  import listMedicament from "./layouts/list.vue";
  import speachTextarea from "@/views/global-components/spechToTextarea";
  import modal from "@/views/global-components/modal";

  export default {
    components: { createMedicament, listMedicament, speachTextarea, modal },
    data() {
      return {
        loader: true,
        saveNote: false,
        modalNewMedicament: false,
        hasOpen: false,
        prescriptionID: null,
        listMedicaments: [],
        note: "",
      };
    },
    watch: {
      note: _.debounce(function (newVal, oldVal) {
        if (oldVal === null) {
          return;
        }

        return this.onSaveNote();
      }, 400),
    },
    mounted() {
      // If Medicament Updated
      EventBus.$on("SUCCESSFULLY_UPDATE_MEDICAMENT", () => {
        console.log("SUCCES EMIT UPDATE"); // If u Want Add some factures after updated medicament .
      });

      // If Medicament created
      EventBus.$on("SUCCESSFULLY_ADD_NEW_MEDICAMENT", (data) => {
        if (data._id) {
          this.listMedicaments.push(data);
        }
      });

      // Open Modal Update Medicament
      EventBus.$on("UPDATE_MEDICAMENT", (data) => {
        this.modalNewMedicament = true;

        this.$nextTick(() => {
          EventBus.$emit("UPDATE_MEDICAMENT_SET_DATA", data);
        });
      });

      // Delete Medicament
      EventBus.$on("DELETE_MEDICAMENT", async (data) => {
        this.$vs.loading();
        this.$store.dispatch("consultation/DELETE_PRESCRIPTIONS", {
          id: data._id,
          onData: ({ ok, message }) => {
            if (ok) {
              this.listMedicaments = this.listMedicaments.filter(
                (item) => item._id !== data._id
              );

              this.$vs.loading.close();
            }

            this.$vs.loading.close();
          },
        });
      });
    },
    created() {
      this.getMedicaments();
    },
    methods: {
      async getMedicaments() {
        await this.$store.dispatch("consultation/GET_PRESCRIPTIONS", {
          id: this.$route.params.id,
          category: "DRUG",
          onData: async ({ ok, data }) => {
            if (ok) {
              this.note = data.note;
              this.prescriptionID = data._id;
              await this.$store.dispatch("consultation/GET_PRESCRIPTION_ITEMS", {
                id: data._id,
                onData: ({ ok: itemOk, data: itemData }) => {
                  if (itemOk) {
                    this.listMedicaments = itemData;
                    this.$nextTick(() => {
                      this.loader = false;
                    });
                  }
                },
              });
            }
          },
        });
      },
      async onGeneration() {
        this.$vs.loading();
        await this.$store.dispatch("consultation/GENERATION_DOCUMENT", {
          data: { id: this.prescriptionID, type: "DRUG" },
          onData: ({ ok, data, message }) => {
            this.$vs.notify({
              time: 4000,
              text: message,
              color: ok ? "success" : "danger",
            });
            this.$vs.loading.close();
          },
        });
      },
      onSaveNote() {
        this.saveNote = true;
        this.$store.dispatch("consultation/SAVE_PRESCRIPTION_NOTE", {
          note: this.note,
          id: this.$route.params.id,
          onData: ({ ok, message }) => {
            if (ok) {
              this.saveNote = false;
            }
          },
        });
      },
    },
  };
</script>
