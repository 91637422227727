<template>
  <!-- ------------------------------------------------ -->
  <!-- HIDDEN  Right now, Until we find the good design -->
  <!-- ------------------------------------------------ -->
  <section id="Medicament-soins">
    <div
      class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-10"
      :class="hasOpen ? 'min-h-20' : ' h-20'"
    >
      <div class="flex justify-between items-center w-full">
        <div class="flex-1 flex flex-row gap-x-1">
          <img src="/svg/consultation/ultrasound.svg" />
          <h1 class="text-xl font-EffraR text-black">Soins paramédicaux</h1>
        </div>

        <button
          class="bg-transparent border-0 p-0 m-0 cursor-pointer"
          @click="hasOpen = !hasOpen"
        >
          <img
            :src="
              hasOpen
                ? '/svg/consultation/chevron-up.svg'
                : '/svg/consultation/chevron-down.svg'
            "
          />
        </button>
      </div>

      <hr v-if="hasOpen" class="border border-solid border-gray-200" />

      <!---------- Content Medicaments -->
      <div class="flex flex-col gap-y-6" v-if="hasOpen">
        <template v-if="!loading">
          <div class="grid grid-cols-1 lg:grid-cols-6 gap-x-3 gap-y-3">
            <div
              v-for="(act, index) in actes"
              :key="index"
              class="rounded p-3 flex flex-row items-center gap-x-3"
              style="border: solid 1px #eaeaea"
            >
              <div>
                <div
                  class="
                    h-8
                    w-8
                    rounded-full
                    cursor-pointer
                    hover:bg-dokBlue-lighter
                    relative
                    flex
                    items-center
                    justify-center
                  "
                  style="border: solid 1px #eaeaea"
                  @click="onClickAct(act.value)"
                >
                  <i
                    class="fas fa-check text-dokBlue-ultra text-xl"
                    v-if="onExistsAct(act.value)"
                  ></i>
                </div>
              </div>
              <h5
                class="font-EffraM text-sm text-dokBlue-ultra truncate"
                :title="act.name"
              >
                {{ act.name }}
              </h5>
            </div>
          </div>

          <div class="flex flex-col gap-y-1">
            <label for="spechText" class="text-base text-black font-EffraR">
              Notes (Optionnel)</label
            >
            <speach-textarea
              id="spechText"
              :content.sync="note"
              holder="Insert text here or dictate by clicking on the button below"
            ></speach-textarea>

            <p class="font-EffraR text-green-700 animate-pulse" v-if="saveNote">
              Enregistrement ...
            </p>
          </div>

          <div class="flex flex-col lg:flex-row lg:justify-end gap-x-6 gap-y-4">
            <dok-button
              size="lg"
              bg="bluelighter"
              custom-class="px-3 flex items-center gap-x-2"
              @click.native="previewActes"
            >
              <img src="/svg/consultation/eye.svg" /> Aperçu
            </dok-button>
            <dok-button
              size="lg"
              custom-class="px-3 flex items-center gap-x-2"
              @click.native="onGeneration"
            >
              <img src="/svg/consultation/sent.svg" />
              Générer l'ordonnance
            </dok-button>
          </div>
        </template>
        <template v-if="loading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="margin: auto; display: block; shape-rendering: auto"
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle cx="30" cy="50" fill="#fd5b68" r="20">
              <animate
                attributeName="cx"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;0.5;1"
                values="30;70;30"
                begin="-0.5s"
              ></animate>
            </circle>
            <circle cx="70" cy="50" fill="#2e6bff" r="20">
              <animate
                attributeName="cx"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;0.5;1"
                values="30;70;30"
                begin="0s"
              ></animate>
            </circle>
            <circle cx="30" cy="50" fill="#fd5b68" r="20">
              <animate
                attributeName="cx"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;0.5;1"
                values="30;70;30"
                begin="-0.5s"
              ></animate>
              <animate
                attributeName="fill-opacity"
                values="0;0;1;1"
                calcMode="discrete"
                keyTimes="0;0.499;0.5;1"
                dur="1s"
                repeatCount="indefinite"
              ></animate>
            </circle>
          </svg>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import speachTextarea from "@/views/global-components/spechToTextarea";

export default {
  components: { speachTextarea },
  data() {
    return {
      loading: true,
      idPrescription: null,
      hasOpen: false,
      selectedActes: [],
      actes: [
        {
          name: "Soins infirmiers",
          value: "Soins infirmiers",
          selected: false,
        },
        { name: "Kinésithérapie", value: "Kinesitherapie", selected: false },
        { name: "Osthéopathie", value: "Ostheopathie", selected: false },
        { name: "Diététique", value: "Dietetique", selected: false },
        { name: "Ergothérapie", value: "Ergotherapie", selected: false },
        { name: "Optique", value: "Optique", selected: false },
        { name: "Orthophonie", value: "Orthophonie", selected: false },
        { name: "Audiologie", value: "Audiologie", selected: false },
        { name: "Puériculture", value: "Puericulture", selected: false },
        { name: "Podologie", value: "Podologie", selected: false },
        { name: "Acupuncture", value: "Acupuncture", selected: false },
        { name: "Psychologie", value: "Psychologie", selected: false },
        { name: "Psycomotricité", value: "Psycomotricite", selected: false },
        { name: "Chiropratique", value: "Chiropratique", selected: false },
        { name: "Etiopahie", value: "Etiopahie", selected: false },
        { name: "Hydrothérapie", value: "Hydrotherapie", selected: false },
        { name: "Orthodontie ", value: "Orthodontie ", selected: false },
        { name: "Radiothérapie", value: "Radiotherapie", selected: false },
      ],
      note: "",
      saveNote: false,
    };
  },
  watch: {
    note: _.debounce(function (newVal, oldVal) {
      if (oldVal === null) {
        return;
      }

      return this.onSaveNote();
    }, 400),
  },
  created() {
    this.getActes();
  },
  methods: {
    onSaveNote() {
      this.saveNote = true;
      this.$store.dispatch("consultation/SAVE_PRESCRIPTION_NOTE", {
        note: this.note,
        category: "ACTS",
        id: this.$route.params.id,
        onData: ({ ok, message }) => {
          if (ok) {
            this.saveNote = false;
          }
        },
      });
    },
    onExistsAct(name) {
      return _.find(this.selectedActes, { name });
    },
    onClickAct(name) {
      if (this.onExistsAct(name)) {
        this.deleteAct(this.onExistsAct(name).id);
        return;
      }

      this.createAct(name);
    },
    deleteAct(id) {
      this.$confirm({
        message: `Êtes-vous sûr(e)?`,
        button: {
          no: "Non",
          yes: "Oui",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$vs.loading();
            this.$store.dispatch("consultation/DELETE_PRESCRIPTIONS", {
              id,
              onData: ({ ok, message }) => {
                if (ok) {
                  this.getActes();
                }
                this.$vs.loading.close();
              },
            });
          }
        },
      });
    },
    previewActes() {
      window.open(
        `https://api.dok.ma/api/v1/prescription/${
          this.idPrescription
        }/preview?token=${this.$auth.getToken()}`,
        "_blank"
      );
    },
    async onGeneration() {
      this.$vs.loading();
      await this.$store.dispatch("consultation/GENERATION_DOCUMENT", {
        data: {
          id: this.idPrescription,
          type: "ACTS",
        },
        onData: ({ ok, data, message }) => {
          this.$vs.notify({
            time: 4000,
            text: message,
            color: ok ? "success" : "danger",
          });
          this.$vs.loading.close();
        },
      });
    },
    async createAct(name) {
      this.$vs.loading();
      const res = await this.$store.dispatch("consultation/CREATE_ACTS", {
        id: this.idPrescription,
        data: {
          name: name,
          refId: "000000000000000000000000",
        },
        onData: ({ data: dataRes, ok: okRes }) => {
          if (okRes) {
            this.getActes();
          }
          this.$vs.loading.close();
        },
      });
    },
    async getActes() {
      this.$store.dispatch("consultation/GET_PRESCRIPTIONS", {
        id: this.$route.params.id,
        category: "ACTS",
        onData: async ({ ok, data }) => {
          if (ok) {
            this.idPrescription = data._id;
            this.note = data.note;
            await this.$store.dispatch("consultation/GET_PRESCRIPTION_ITEMS", {
              id: data._id,
              onData: ({ ok: itemOk, data: itemData }) => {
                if (itemOk) {
                  if (itemData.length) {
                    this.selectedActes = itemData.map((item) => {
                      return { name: item.name, id: item._id };
                    });
                  }

                  this.loading = false;
                }
              },
            });
          }
        },
      });
    },
  },
};
</script>

<style></style>
