<template>
  <div>
    <div class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-10">
      <div class="flex flex-row gap-x-1">
        <img src="/svg/consultation/folder.svg" />
        <h1 class="text-xl font-EffraR text-black">Documents générés</h1>
      </div>

      <template v-if="!loader">
        <div class="flex flex-col gap-y-4">
          <list-documents
            v-for="(file, indexItem) in files"
            :key="indexItem"
            :item="file"
          ></list-documents>

          <div
            v-if="!files.length"
            class="flex items-center justify-center gap-x-2 font-EffraM text-lg"
            style="color: #A3A3A3;"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="#A3A3A3"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 5L5 15M5 5l10 10"
              />
            </svg>
            Pas de données
          </div>
        </div>
      </template>
      <template v-if="loader">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="margin: auto; display: block; shape-rendering: auto"
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
          </circle>
          <circle cx="70" cy="50" fill="#2e6bff" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="0s"
            ></animate>
          </circle>
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
            <animate
              attributeName="fill-opacity"
              values="0;0;1;1"
              calcMode="discrete"
              keyTimes="0;0.499;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            ></animate>
          </circle>
        </svg>
      </template>

      <div class="flex flex-col gap-y-3">
        <div class="flex justify-end">
          <dok-button
            size="md"
            custom-class="px-10"
            @click.native="modal.is = true"
          >
            Ajouter un document
          </dok-button>
        </div>
      </div>
    </div>
    <!------------------------------ Form Upload File ------------------------------>
    <modal
      :isActive="modal.is"
      :exitModal="
        () => {
          modal = {
            is: false,
            file: null,
            shareWithPatient: false,
            description: '',
            categoryId: null,
            name: null,
            tags: ['dokMaroc', 'TestTags', 'NazihTest']
          };
        }
      "
      :width="600"
      :maxHeight="650"
    >
      <div class="flex flex-col gap-y-2">
        <div class="flex justify-start mx-5 mt-5">
          <dok-button
            size="md"
            custom-class="flex items-center gap-x-2 relative"
          >
            <input
              type="file"
              class="absolute bg-red-500 inset-0 opacity-0"
              @change="setFile"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21.44 11.05l-9.19 9.19c-1.126 1.126-2.653 1.758-4.245 1.758s-3.12-.632-4.245-1.758C2.634 19.114 2 17.587 2 15.995s.633-3.12 1.759-4.245l9.19-9.19c.75-.75 1.768-1.172 2.83-1.172 1.061 0 2.08.421 2.83 1.172.75.75 1.172 1.769 1.172 2.83 0 1.061-.422 2.08-1.172 2.83l-9.2 9.19c-.375.375-.884.586-1.415.586-.53 0-1.04-.21-1.415-.586-.375-.375-.586-.884-.586-1.415 0-.53.21-1.04.586-1.415l8.49-8.48"
              />
            </svg>
            Télécharger un document
          </dok-button>
        </div>

        <div
          class="mx-5 flex justify-between items-center gap-x-3 font-EffraR text-base"
          v-if="modal.file"
        >
          <div class="flex gap-x-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16"
            >
              <path
                stroke="#111"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.4"
                d="M8.666 1.333H3.999c-.353 0-.692.14-.942.39s-.391.59-.391.944v10.666c0 .354.14.693.39.943s.59.39.943.39h8c.354 0 .693-.14.943-.39s.39-.59.39-.943V6L8.667 1.333z"
              />
              <path
                stroke="#111"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.4"
                d="M8.666 1.333V6h4.667"
              />
            </svg>
            {{ modal.file.name }}
          </div>

          <span class="cursor-pointer" @click="modal.file = null">
            <i class="far fa-trash-alt text-lg text-red-600"></i>
          </span>
        </div>

        <div
          class="hidden bg-dokBlue-lighter rounded-cu p-3 m-5 flex flex-col gap-y-2"
        >
          <h5 class="flex items-center font-EffraM text-black gap-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16"
            >
              <g clip-path="url(#clip0)">
                <path
                  stroke="#111"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 14.667c3.682 0 6.666-2.985 6.666-6.667S11.682 1.333 8 1.333C4.318 1.333 1.333 4.318 1.333 8S4.318 14.667 8 14.667zM8 10.667V8M8 5.333h.007"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <path fill="#fff" d="M0 0H16V16H0z" />
                </clipPath>
              </defs>
            </svg>
            Instructions
          </h5>

          <span class="font-EffraR text-black">
            Les fichiers doivent être en png, jpeg <br />
            L'image doit être de haute qualité, claire et lisible.
          </span>
        </div>

        <hr class="border-0 border-t border-solid border-grayxx" />

        <div class="m-5 flex flex-col gap-y-1">
          <span class="font-EffraR text-base text-gray-800">
            Tags:
          </span>
          <tags-input :tags="modal.tags"></tags-input>
        </div>
        <hr class="border-0 border-t border-solid border-grayxx" />

        <div class="m-5">
          <dok-input
            size="lg"
            d-placeholder="Nom du document"
            d-type="text"
            label="Nom du document :"
            :d-model.sync="modal.name"
          >
          </dok-input>
        </div>
        <hr class="border-0 border-t border-solid border-grayxx" />

        <div class="m-5 flex flex-col gap-y-1">
          <span class="font-EffraR text-base text-gray-800">
            Type de documents
          </span>
          <div class="flex flex-row gap-x-5">
            <div
              class="flex items-center gap-x-2"
              v-for="(cat, index) in categories"
              :key="index"
            >
              <input
                type="radio"
                :value="cat._id"
                v-model="modal.categoryId"
                :id="cat._id"
                class="form-radio border border-solid border-dokBlue-light text-xl text-dokBlue-ultra focus:outline-none focus:shadow-none"
              />
              <label :for="cat._id" class="font-EffraR text-base text-black">{{
                cat.title
              }}</label>
            </div>
          </div>
        </div>
        <hr class="border-0 border-t border-solid border-grayxx" />

        <div class="m-5">
          <dok-textarea
            size="md"
            d-placeholder="Note"
            d-type="text"
            label="Description :"
            :d-model.sync="modal.description"
          >
          </dok-textarea>
        </div>
        <hr class="border-0 border-t border-solid border-grayxx" />

        <div class="m-5 flex flex-col gap-y-3">
          <div class="flex gap-x-3 items-center">
            <toggle :is-active.sync="modal.shareWithPatient"></toggle>
            <span class="font-EffraR text-black text-base"
              >Partager ce fichier avec le patient</span
            >
          </div>

          <dok-button
            size="md"
            type="submit"
            custom-class="w-full"
            @click.native="modal._id ? onUpdate() : onUpload()"
          >
            {{ modal._id ? "Modifier" : "Créer" }}
          </dok-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";
import listDocuments from "./layouts/list.vue";
import uploadDocument from "../globalComponents/upload.vue";
import modal from "@/views/global-components/dragModal";
import toggle from "../globalComponents/switchRadio.vue";
import tagsInput from "../globalComponents/tagsInput.vue";

export default {
  data() {
    return {
      loader: true,
      categories: [],
      files: [],
      modal: {
        is: false,
        file: null,
        shareWithPatient: false,
        description: "",
        categoryId: null,
        name: null,
        tags: []
      }
    };
  },
  components: { listDocuments, uploadDocument, modal, toggle, tagsInput },
  mounted() {
    EventBus.$on("ON_EDIT_DOCUMENT_UPLOAD", item => {
      this.modal.description = item.description;
      this.modal.categoryId = item.category._id;
      this.modal.name = item.name;
      this.modal.tags = item.tags;
      this.modal._id = item._id;

      this.$nextTick(() => {
        this.modal.is = true;
      });
    });

    EventBus.$on("ON_DELETE_DOCUMENT_UPLOAD", async item => {
      await this.onDelete(item._id);
    });
  },
  created() {
    this.$vs.loading();
    this.getDocuments();
    this.getCategorysFile();
  },
  methods: {
    ...mapActions({
      getConsultationDocuments: "consultation/GET_FILES",
      assignFile: "consultation/ASSIGN_FILE",
      getFilesCategories: "file/GET_FILES_CATEGORIES"
    }),
    async getCategorysFile() {
      await this.getFilesCategories({
        onData: ({ ok, data }) => {
          if (ok) {
            this.categories = data;
          }
        }
      });
    },
    async getDocuments() {
      await this.getConsultationDocuments({
        id: this.$route.params.id,
        onData: ({ ok, data }) => {
          if (ok) {
            this.files = data;
          }

          this.$vs.loading.close();
          this.$nextTick(() => {
            this.loader = false;
          });
        }
      });
    },
    async onUpload() {
      this.$vs.loading();
      await this.assignFile({
        data: this.modal,
        id: this.$route.params.id,
        onData: ({ data, ok }) => {
          if (!ok) {
            EventBus.$emit("flashDok", {
              type: "errorr",
              message: `Tous les champs sont requis`,
              timerAlert: 8000
            });
            return this.$vs.loading.close();
          }

          if (ok) {
            this.modal = {
              is: false,
              file: null,
              shareWithPatient: false,
              description: "",
              categoryId: null,
              name: null,
              tags: []
            };
            this.getDocuments();
          }
          this.$vs.loading.close();
        }
      });
    },
    async onUpdate() {
      console.log("UPODATE FILE");
    },
    async onDelete(id) {
      event.preventDefault();

      this.$vs.loading();
      await this.$store.dispatch("consultation/REMOVE_FILE", {
        documentId: id,
        consultationId: this.$route.params.id,
        onData: data => {
          this.getDocuments();
        }
      });
    },
    setFile() {
      this.modal.file = event.target.files[0];
      this.modal.name = event.target.files[0].name;
    }
  }
};
</script>
