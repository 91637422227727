<template>
  <div
    class="flex gap-x-2 border-2 border-solid border-borderInput rounded px-1 w-full text-black py-2"
    style="min-height: 48px;"
  >
    <div class="flex flex-wrap items-center gap-x-4 gap-y-2 w-full">
      <div
        v-for="(tag, index) in tags"
        :key="index"
        class="gap-x-1 inline-flex z-10 bg-dokBlue-lighter text-dokBlue-ultra rounded"
      >
        <span
          class="bg-dokBlue-ultra text-white px-2 flex items-center justify-center cursor-pointer rounded-l"
          @click="deleteTag(index)"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span class="px-2 py-1 font-EffraR">{{ tag }}</span>
      </div>
      <input
        type="text"
        placeholder="Saisissez un tag"
        class="border-0 font-EffraR text-black placeholder-placeholderInput"
        style="font-size: 16px;"
        :class="tags.length ? 'w-auto' : 'w-full'"
        @keydown.enter="onAddNewTag"
        @keydown.188="onAddNewTag"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tags: {
      type: Array,
      required: true
    }
  },
  methods: {
    onAddNewTag() {
      event.preventDefault();

      if (!event.target.value) return;

      this.tags.push(event.target.value);

      this.$nextTick(() => {
        event.target.value = null;
      });
    },
    deleteTag(index) {
      this.tags.splice(index, 1);
    }
  }
};
</script>
