<template>
  <div class="flex flex-col gap-y-4 w-full">
    <dok-medicaments class="w-full" />
    <dok-analyses class="w-full" />
    <dok-imageries class="w-full" />
    <dok-actes-paramedicaux
      v-if="patient"
      :patient="patient"
    ></dok-actes-paramedicaux>
    <dok-certificats v-if="patient" :patient="patient" class="w-full" />
  </div>
</template>
<script>
import dokMedicaments from "./medicaments/index.vue";
import dokAnalyses from "./analyses/index.vue";
import dokActes from "./actes/index.vue";
import dokImageries from "./imageries/index2.vue";
import dokCertificats from "./certificats_rapports/index.vue";
import dokActesParamedicaux from "./actes_paramedicaux/index2.vue";
export default {
  props: {
    patient: {
      type: Object,
      required: true,
    },
  },
  components: {
    dokMedicaments,
    dokAnalyses,
    dokActes,
    dokImageries,
    dokCertificats,
    dokActesParamedicaux,
  },
};
</script>
