<template>
  <div class="flex flex-col gap-y-2">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="border border-solid border-otgray rounded-lg p-8 grid gap-x-3 grid-cols-1 lg:grid-cols-6"
    >
      <div class="flex flex-col gap-y-1">
        <span class="text-sm font-EffraR" style="color: #767676;"
          >Nom du médicament</span
        >
        <span class="text-base text-black font-EffraM">{{ item.name }}</span>
      </div>
      <div class="flex flex-col gap-y-1">
        <span class="text-sm font-EffraR" style="color: #767676;">Durée</span>
        <span class="text-base text-black font-EffraM">
          {{
            `${item.treatmentDuration} jour` | pluralize(item.treatmentDuration)
          }}
        </span>
      </div>
      <div class="flex flex-col gap-y-1">
        <span class="text-sm font-EffraR" style="color: #767676;"
          >Dosage/Prise</span
        >
        <span class="text-base text-black font-EffraM">
          {{
            `${item.dailyDosage ? $toFraction(item.dailyDosage) : ""} ${
              item.dailyDosage &&
              item.doseUnit &&
              $medicamentDosageUnits[item.doseUnit]
                ? $medicamentDosageUnits[item.doseUnit].unity
                : ""
            }`
          }}
        </span>
      </div>
      <div class="flex flex-col gap-y-1">
        <span class="text-sm font-EffraR" style="color: #767676;"
          >Prise/Jour</span
        >
        <span class="text-base text-black font-EffraM">
          {{ `${item.numberOfTake ? item.numberOfTake + "x/jour" : ""}` }}
        </span>
      </div>
      <div class="flex flex-col gap-y-1">
        <span class="text-sm font-EffraR" style="color: #767676;"
          >Prise/Repas</span
        >
        <span class="text-base text-black font-EffraM">
          {{ $timesPeriod[item.timePeriod] }}
          {{ item.timePeriod && item.mealPeriod ? "/" : "" }}
          {{ $mealFrancais(item.mealPeriod) }}
        </span>
      </div>
      <div class="flex flex-row justify-end gap-x-1">
        <button
          class="
              border-0
              bg-transparent
              hover:bg-gray-200
              cursor-pointer
              h-12
              w-12
              rounded-full
              flex
              items-center
              justify-center
              button-tooltip
              group
              focus:bg-dokBlue-lighter
            "
          data-tooltip="Modifier"
          aria-label="Modifier"
          @click="onUpdate(item)"
        >
          <svg
            class="stroke-current text-black group-focus:text-dokBlue-ultra"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M16 3l5 5L8 21H3v-5L16 3z"
            />
          </svg>
        </button>
        <button
          class="
              border-0
              bg-transparent
              hover:bg-gray-200
              cursor-pointer
              h-12
              w-12
              rounded-full
              flex
              items-center
              justify-center
              button-tooltip
              group
              focus:bg-dokBlue-lighter
            "
          data-tooltip="Supprimer"
          aria-label="Supprimer"
          @click="onDelete(item)"
        >
          <svg
            class="stroke-current text-red-600 group-focus:text-dokBlue-ultra"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 6h18M8 6V4c0-.53.21-1.04.586-1.414C8.96 2.21 9.47 2 10 2h4c.53 0 1.04.21 1.414.586C15.79 2.96 16 3.47 16 4v2m3 0v14c0 .53-.21 1.04-.586 1.414C18.04 21.79 17.53 22 17 22H7c-.53 0-1.04-.21-1.414-.586C5.21 21.04 5 20.53 5 20V6h14z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus/index.js";

export default {
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  methods: {
    onUpdate(item) {
      EventBus.$emit("UPDATE_MEDICAMENT", item);
    },
    onDelete(item) {
      EventBus.$emit("flashDok", {
        defualtTime: 30000,
        type: "delete",
        title: "Suppression du médicament",
        message: "Êtes-vous sûrs de vouloir supprimer ce médicament?",
        successmessage: "Rendez-vous supprimé avec succès",
        confirm: () => {
          return EventBus.$emit("DELETE_MEDICAMENT", item);
        },
        cancel: callback => {
          return callback();
        }
      });
    }
  }
};
</script>

<style></style>
