<template>
  <section
    id="manage_model_paramedical"
    class="mt-2 bg-white px-8 rounded-lg py-5 flex flex-col gap-y-5"
  >
    <dok-input
      label="Nom dul model"
      type="text"
      size="lg"
      :d-model.sync="data.modelName"
    />

    <quill-editor
      ref="createModelRef"
      :content="data.content"
      placeholder="Contenu du Certificat"
      :options="editorOptions"
      @change="onEditContent($event)"
    />

    <div class="flex justify-between gap-x-6">
      <dok-button
        v-if="certifModel != null"
        size="md"
        bg="red"
        custom-class="px-3 flex items-center gap-x-2"
        @click.native="deleteModel"
      >
        Supprimer le model
      </dok-button>
      <dok-button
        size="md"
        custom-class="px-3 flex items-center gap-x-2"
        @click.native="saveModel"
      >
        Sauvegarder le model
      </dok-button>
    </div>
  </section>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";

// Import Editor
import { Quill, quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // import styles
export default {
  components: {
    quillEditor
  },
  created() {
    var icons = Quill.import("ui/icons");
    // icons["doctor_city"] = "doctor city";
    // icons["doctor_name"] = "doctor name";
    icons["patient_name"] = '<i class="fa fa-user"></i> Nom';
    icons["patient_birthday"] = "DDN";
    icons["patient_cin"] = "CIN";
    icons["patient_sexe"] = "Sexe";
  },
  mounted() {
    const self = this;
    this.quill = this.$refs.createModelRef.quill;

    this.modelId = this.certifModel._id;
    this.content = this.certifModel.content;
    this.modelName = this.certifModel.name;
    // console.log(this.$refs.createModelRef.quill, "dd")
  },
  props: {
    certifModel: { type: Object, required: false }
  },
  data() {
    return {
      data: {
        parentSoin:this.certifModel.parentSoin,
        modelId: this.certifModel._id,
        content: this.certifModel.model,
        modelName: this.certifModel.name
      },
      editorOptions: {
        theme: "snow",
        placeholder: "Saisissez votre texte ...",
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["bold", "italic", "underline", "strike"],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ align: [] }],
              [{ color: [] }, { background: [] }],
              ["link", "image"],
              [
                // {"doctor_city":"[[doctor_city]]"},
                // {"doctor_name":"[[doctor_name]]"},
                { patient_name: "[[patient_name]]" },
                { patient_birthday: "[[patient_birthday]]" },
                { patient_cin: "[[patient_cin]]" },
                { patient_sexe: "[[patient_sexe]]" }
              ]
            ],
            handlers: {
              patient_name: this.insertCode,
              patient_birthday: this.insertCode,
              patient_cin: this.insertCode,
              patient_sexe: this.insertCode
            }
          }
        }
      }
    };
  },
  methods: {
    insertCode(code) {
      var { index, length } = this.quill.getSelection();
      if (length > 0) {
        this.quill.deleteText(index, length);
      }

      this.quill.insertText(index, code + " ", "", true);
    },
    onEditContent(event) {
      if (event.html) {
        this.data.content = event.html;
      }
    },
    async saveModel() {
      var $_this = this;
      if (this.modelId) {

        await this.$store.dispatch("consultation/UPDATE_DOCTOR_PARA_MEDICAL_MODEL", {
          modelId: this.modelId,
          params: {
            content: this.data.content,
            name: this.data.modelName,
            parentSoin: this.data.parentSoin
          },
          onData: function(data) {
            $_this.$emit("refreshListPreview", data.model);
          }
        });
      } else {
        await this.$store.dispatch("consultation/SAVE_DOCTOR_PARA_MEDICAL_MODEL", {
          params: {
            content: this.data.content,
            name: this.data.modelName,
            parentSoin: this.data.parentSoin
          },
          onData: function(data) {
            $_this.$emit("refreshListPreview", data.model);
          }
        });
      }
    },
    async deleteModel() {
      const $_this = this;
      var conf = confirm("Voulez-vous vraiment supprimer ce model ?");
      if (conf) {
        await this.$store.dispatch("consultation/DELETE_DOCTOR_PARA_MEDICAL_MODEL", {
          modelId: this.modelId,

          onData: function(data) {
            $_this.$emit("refreshListPreview");
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" >
.ql-toolbar button {
  &.ql-patient_name,
  &.ql-patient_birthday,
  &.ql-patient_cin,
  &.ql-patient_sexe {
    width: 65px;
    @apply rounded-md bg-dokBlue-ultra text-white mr-2 font-EffraR text-base shadow;
    &:hover {
      color: #fff;
    }
  }
}
</style>
