<template>
  <div class="flex flex-col gap-y-6" v-if="prescriptionId">
    <h1 class="text-2xl font-EffraR text-black">Ajouter un médicament</h1>

    <!-- Search Medicament Algolia -->
    <div class="relative w-full lg:w-450">
      <input
        type="text"
        placeholder="Rechercher un médicament ..."
        class="
          h-12
          bg-transparent
          text-black
          font-EffraR
          text-lg
          px-2
          rounded-cu
          w-full
        "
        style="border: solid 2px #aaaaaa"
        v-model="data.name"
      />
      <div
        v-if="showListMedicament"
        class="
          bg-white
          border border-solid border-graydok
          shadow
          rounded
          absolute
          z-1
          w-full
          mt-2
        "
      >
        <div class="flex justify-end">
          <dok-button
            bg="none"
            @click.native="
              () => {
                showListMedicament = false;
              }
            "
          >
            <img src="/svg/x.svg" />
          </dok-button>
        </div>
        <div class="max-h-48 overflow-y-scroll scroll">
          <div class="flex flex-col gap-y-0">
            <div
              class="
                font-EffraR
                text-base
                cursor-pointer
                px-2
                py-3
                hover:bg-grayxx
              "
              v-for="(im, ix) in onDataMedicaments"
              :key="ix"
              @click="setMedicament(im)"
            >
              {{ im.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        grid grid-cols-1
        lg:grid-cols-3
        gap-x-10 gap-y-8
        items-baseline
        px-20
      "
    >
      <!-- Nombre de prise -->
      <div
        class="
          relative
          p-1
          rounded-cu
          hover:bg-greyx
          cursor-pointer
          flex flex-col
          gap-y-1
        "
      >
        <label
          class="font-EffraR text-sm"
          style="color: #767676"
          for="numbPrise"
        >
          Dosage
        </label>
        <input
          id="numbPrise"
          placeholder="Dosage/Prise"
          class="border-0 bg-transparent font-EffraR"
          type="number"
          v-model="data.dailyDosage"
        />
      </div>
      <!-- Dosage/prise -->
      <div
        class="
          relative
          p-1
          rounded-cu
          hover:bg-greyx
          cursor-pointer
          flex flex-col
          gap-y-1
        "
      >
        <label
          class="font-EffraR text-sm"
          style="color: #767676"
          for="dosageSelect"
        >
          Unité (g, sachet, gelule...)
        </label>
        <select
          id="dosageSelect"
          class="border-0 bg-transparent font-EffraR text-base"
          v-model="data.doseUnit"
        >
          <option :value="null" selected disabled class="font-EffraR">
            Sélectionnez une unité..
          </option>
          <option
            v-for="(dure, index) in $medicamentDosageUnits"
            :key="index"
            :value="index"
            class="font-EffraR"
          >
            {{ `${dure.unity}` }}
          </option>
        </select>
      </div>
      <!-- Durée -->
      <div
        class="
          relative
          p-1
          rounded-cu
          hover:bg-greyx
          cursor-pointer
          flex flex-col
          gap-y-1
        "
      >
        <label
          class="font-EffraR text-sm"
          style="color: #767676"
          for="dureeSelect"
        >
          Durée
        </label>
        <select
          id="dureeSelect"
          class="border-0 bg-transparent font-EffraR text-base"
          v-model="data.treatmentDuration"
        >
          <option :value="null" selected disabled class="font-EffraR">
            Sélectionnez une durée
          </option>

          <option
            v-for="(dure, index) in numberDuree()"
            :key="index"
            :value="dure.number"
            class="font-EffraR"
          >
            {{ `${dure.number < 30 ? dure.number : ""} ${dure.label}` }}
          </option>
        </select>
      </div>
    </div>

    <div
      class="
        grid grid-cols-1
        lg:grid-cols-3
        gap-x-10 gap-y-8
        items-baseline
        px-20
      "
    >
      <!-- Number De Prise/Jour -->
      <div
        class="
          relative
          p-1
          rounded-cu
          hover:bg-greyx
          cursor-pointer
          flex flex-col
          gap-y-1
        "
      >
        <label
          class="font-EffraR text-sm"
          style="color: #767676"
          for="dayNumber"
        >
          Nombre De Prise/Jour
        </label>
        <select
          id="dayNumber"
          class="border-0 bg-transparent font-EffraR text-base"
          v-model="data.numberOfTake"
        >
          <option
            v-for="(dure, index) in numberPrise()"
            :key="index"
            :value="dure.number"
          >
            {{ `${dure.label}` }}
          </option>
        </select>
      </div>
      <!-- Prise -->
      <div class="flex flex-col gap-y-1">
        <label class="text-sm font-EffraR" style="color: #767676">Prise</label>
        <div class="flex flex-row gap-x-4 gap-y-1">
          <div
            class="flex flex-row items-center gap-x-2"
            v-for="(pris, index) in $timesPeriodByNbrPrise[data.numberOfTake]"
            :key="index"
          >
            <input
              v-model="data.timePeriod"
              type="radio"
              :id="pris"
              class="form-radio border-2 border-solid border-graylabel text-xl"
              :value="index"
            />
            <label :for="pris" class="font-EffraR text-base">
              {{ pris }}
            </label>
          </div>
        </div>
      </div>
      <!-- Repas -->
      <div class="flex flex-col gap-y-1">
        <label class="text-sm font-EffraR" style="color: #767676">Repas</label>
        <div class="flex flex-row gap-x-4 gap-y-1">
          <div class="flex flex-col gap-y-1">
            <div class="flex flex-row items-center gap-x-2">
              <input
                v-model="data.mealPeriod"
                type="radio"
                id="BEFORE"
                class="
                  form-radio
                  border-2 border-solid border-graylabel
                  text-xl
                "
                value="BEFORE"
              />
              <label for="BEFORE" class="font-EffraR text-base">
                Avant repas
              </label>
            </div>
          </div>
          <div class="flex flex-col gap-y-1">
            <div class="flex flex-row items-center gap-x-2">
              <input
                v-model="data.mealPeriod"
                type="radio"
                id="BETWEEN"
                class="
                  form-radio
                  border-2 border-solid border-graylabel
                  text-xl
                "
                value="BETWEEN"
              />
              <label for="BETWEEN" class="font-EffraR text-base">
                Pendant repas
              </label>
            </div>
          </div>
          <div class="flex flex-col gap-y-1">
            <div class="flex flex-row items-center gap-x-2">
              <input
                v-model="data.mealPeriod"
                type="radio"
                id="AFTER"
                class="
                  form-radio
                  border-2 border-solid border-graylabel
                  text-xl
                "
                value="AFTER"
              />
              <label for="AFTER" class="font-EffraR text-base">
                Après repas
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center w-full lg:w-450 px-20">
      <dok-button
        size="lg"
        @click.native="data._id ? onUpdate() : onAdd()"
        custom-class="w-full"
      >
        {{
          data._id ? "Mise à jour de l'ordonnance" : "Ajouter la prescription"
        }}
      </dok-button>
    </div>
  </div>
</template>

<script>
  import { EventBus } from "@/event-bus/index.js";
  import dokDropdown from "@/views/dashboard/consultationsN/globalComponents/select.vue";
  import algoliasearch from "algoliasearch/lite";

  export default {
    props: {
      prescriptionId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        stopWatch: false,
        showListMedicament: false,
        onDataMedicaments: [],
        searchClient: algoliasearch(
          "2BJUOM1BDB",
          "7906f008c0346b78f39d051fa293387f"
        ),
        data: {
          dailyDosage: 1,
          name: null,
          treatmentDuration: 1,
          doseUnit: null,
          numberOfTake: 1,
          timePeriod: null,
          mealPeriod: "BEFORE",
        },
      };
    },
    mounted() {
      EventBus.$on("UPDATE_MEDICAMENT_SET_DATA", (data) => {
        this.stopWatch = true;
        this.data = data;
        setTimeout(() => {
          this.stopWatch = false;
        }, 3000);
      });
      this.data.timePeriod = 0;
    },
    watch: {
      "data.name"(newVal, oldVal) {
        if (this.stopWatch) {
          return;
        }
        if (newVal && newVal.length > 3) {
          this.onSearchMedicaments(newVal);
          this.setUnite();
        }
      },
    },
    methods: {
      numberDuree() {
        let jours_modifi = [];
        for (var i = 0; i < 29; i++) {
          jours_modifi.push({
            number: i + 1,
            label: i > 0 ? "Jours" : "Jour",
          });
        }
        jours_modifi.push({
          number: 30,
          label: "1 Mois",
        });
        jours_modifi.push({
          number: 45,
          label: "1 Mois et demi",
        });
        jours_modifi.push({
          number: 60,
          label: "2 Mois",
        });
        jours_modifi.push({
          number: 75,
          label: "2 Mois et demi",
        });
        jours_modifi.push({
          number: 90,
          label: "3 Mois",
        });
        jours_modifi.push({
          number: 1800,
          label: "6 Mois",
        });
        jours_modifi.push({
          number: 365,
          label: "1 An",
        });
        return jours_modifi;
      },
      numberPrise() {
        let jours = new Array(12);
        let jours_modifi = [];
        for (var i = 0; i < jours.length; i++) {
          jours_modifi.push({
            number: i + 1,
            label: `${i + 1} x/Jour`,
          });
        }

        return jours_modifi;
      },
      onSearchMedicaments: async function (val) {
        let index_search = this.searchClient.initIndex("dokma_dev_drugs");
        index_search.search(val).then(({ hits }) => {
          if (hits.length) {
            this.onDataMedicaments = hits;
            this.$nextTick(() => {
              this.showListMedicament = true;
            });
          }
        });
      },
      setMedicament(item) {
        if (item.name) {
          this.data.name = item.name;
          this.data.refId = item.objectID;
          this.$nextTick(() => {
            setTimeout(() => {
              this.showListMedicament = false;
            }, 100);
          });
        }
      },
      setUnite() {
        let words = this.data.name.split(",");
        if (words.length > 1) {
          let temp = words[words.length - 1].trim().split(" ");
          for (let item of temp) {
            switch (item.trim().toLowerCase()) {
              case "injectable":
                this.data.doseUnit = "injection";
                return;
              case "injection":
                this.data.doseUnit = "injection";
                return;
              case "comprimé":
                this.data.doseUnit = "comprime";
                return;
              case "sirop":
                this.data.doseUnit = "sirop";
                return;
              case "sachet":
                this.data.doseUnit = "sachet";
                return;
              case "goutte":
                this.data.doseUnit = "goutte";
                return;
              case "gélule":
                this.data.doseUnit = "gelule";
                return;
              case "pulvérisation":
                this.data.doseUnit = "pulverisation";
                return;
            }
            for (let o of Object.values(this.$medicamentDosageUnits)) {
              if (o.name.trim().toLowerCase() === item.trim().toLowerCase()) {
                this.data.doseUnit = o.name.trim().toLowerCase();
                return;
              }
            }
          }
        }
      },
      async onAdd() {
        if (
          !this.data.name ||
          !this.data.dailyDosage ||
          !this.data.treatmentDuration ||
          !this.data.doseUnit ||
          !this.data.numberOfTake ||
          !this.data.timePeriod ||
          !this.data.mealPeriod
        ) {
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message: `Remplissez les champs manquants`,
            timerAlert: 8000,
          });
        }

        this.$vs.loading();
        await this.$store.dispatch("consultation/CREATE_PRESCRIPTIONS", {
          id: this.prescriptionId,
          data: this.data,
          onData: ({ ok, data }) => {
            if (ok) {
              EventBus.$emit("flashDok", {
                type: "success",
                message: `Médicament ajouté avec succès`,
                timerAlert: 8000,
              });

              EventBus.$emit("SUCCESSFULLY_ADD_NEW_MEDICAMENT", data);
              this.data = {
                dailyDosage: null,
                name: null,
                treatmentDuration: null,
                doseUnit: null,
                numberOfTake: 1,
                timePeriod: null,
                mealPeriod: null,
              };
            }
            this.$vs.loading.close();
          },
        });
      },
      async onUpdate() {
        if (
          !this.data.name ||
          !this.data.dailyDosage ||
          !this.data.treatmentDuration ||
          !this.data.doseUnit ||
          !this.data.numberOfTake ||
          !this.data.timePeriod ||
          !this.data.mealPeriod
        ) {
          return EventBus.$emit("flashDok", {
            type: "errorr",
            message: `Remplissez les champs manquants`,
            timerAlert: 8000,
          });
        }

        this.$vs.loading();
        await this.$store.dispatch("consultation/UPDATE_PRESCRIPTIONS", {
          id: this.data._id,
          data: this.data,
          onData: ({ ok, message }) => {
            if (ok) {
              EventBus.$emit("flashDok", {
                type: "success",
                message: `Médicament modifié avec succès`,
                timerAlert: 8000,
              });

              this.data = {
                dailyDosage: null,
                name: null,
                treatmentDuration: null,
                doseUnit: null,
                numberOfTake: 1,
                timePeriod: null,
                mealPeriod: null,
              };
              EventBus.$emit("SUCCESSFULLY_UPDATE_MEDICAMENT", {});
            }
            this.$vs.loading.close();
          },
        });
      },
    },
    components: { dokDropdown },
  };
</script>
