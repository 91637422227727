<template>
  <div class="flex flex-col gap-y-3">
    <div
      class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-6 w-full"
      style="border: solid 2px #ededed;"
    >
      <div class="flex flex-row gap-x-1">
        <img src="/svg/consultation/info.svg" />
        <h1 class="text-xl font-EffraR text-black">
          Informations
        </h1>
      </div>

      <hr class="border border-solid border-gray-200" />

      <div class="grid gap-x-24 gap-y-6 grid-cols-1 xl:grid-cols-3">
        <div class="flex flex-col gap-y-1">
          <label
            for="fullName"
            class="font-EffraM text-base flex items-center gap-x-1"
            style="color: #6d6d6d;"
            ><img src="/svg/consultation/info/body-scan.svg" /> Prénom et
            nom</label
          >
          <div class="font-EffraM text-black text-base">
            {{ `${data.firstName} ${data.lastName}` }} (
            {{ data.gender ? "M" : "F" }} )
          </div>
        </div>
        <div class="flex flex-col gap-y-1">
          <label
            for="fullName"
            class="font-EffraM text-base flex items-center gap-x-1"
            style="color: #6d6d6d;"
            ><img src="/svg/consultation/info/phone.svg" /> Numéro de
            téléphone</label
          >
          <div class="font-EffraM text-black text-base">
            {{ data.mobile || "--" }}
          </div>
        </div>
        <div class="flex flex-col gap-y-1">
          <label
            for="fullName"
            class="font-EffraM text-base flex items-center gap-x-1"
            style="color: #6d6d6d;"
          >
            <img src="/svg/consultation/info/briefcase.svg" />
            Profession
          </label>
          <div class="font-EffraM text-black text-base">
            {{ data.job || "--" }}
          </div>
        </div>
        <div class="flex flex-col gap-y-1">
          <label
            for="fullName"
            class="font-EffraM text-base flex items-center gap-x-1"
            style="color: #6d6d6d;"
          >
            <img src="/svg/consultation/info/credit-card.svg" /> CIN
          </label>
          <div class="font-EffraM text-black text-base">
            {{ data.CIN || "--" }}
          </div>
        </div>
        <div class="flex flex-col gap-y-1">
          <label
            for="fullName"
            class="font-EffraM text-base flex items-center gap-x-1"
            style="color: #6d6d6d;"
          >
            <img src="/svg/consultation/info/body-scan.svg" /> Adresse
          </label>
          <div
            class="font-EffraM text-black text-base text flex items-center gap-x-2"
          >
            {{
              data.landingPage &&
              data.landingPage.address &&
              data.landingPage.address.label
                ? data.landingPage.address.label
                : "--"
            }}
            <img
              v-if="
                data.landingPage &&
                  data.landingPage.address &&
                  data.landingPage.address.label
              "
              src="/svg/consultation/info/external-link.svg"
              class="cursor-pointer"
            />
          </div>
        </div>
        <div class="flex flex-col gap-y-1">
          <label
            for="fullName"
            class="font-EffraM text-base flex items-center gap-x-1"
            style="color: #6d6d6d;"
          >
            <img src="/svg/consultation/info/flag.svg" /> Langues
          </label>
          <div class="font-EffraM text-black text-base">
            {{
              data.landingPage && data.landingPage.languages.length
                ? data.landingPage.languages.join(", ")
                : "--"
            }}
          </div>
        </div>
        <div class="flex flex-col gap-y-1">
          <label
            for="fullName"
            class="font-EffraM text-base flex items-center gap-x-1"
            style="color: #6d6d6d;"
          >
            <img src="/svg/consultation/info/calendar.svg" /> Date et lieu de
            naissance
          </label>
          <div class="font-EffraM text-black text-base">
            {{ data.birthday | moment("DD/MM/YYYY") }}
            {{
              data.landingPage &&
              data.landingPage.address &&
              data.landingPage.address.city
                ? ` , ${data.landingPage.address.city.name}`
                : null
            }}
          </div>
        </div>
        <div class="flex flex-col gap-y-1">
          <label
            for="fullName"
            class="font-EffraM text-base flex items-center gap-x-1"
            style="color: #6d6d6d;"
          >
            <img src="/svg/consultation/info/mail.svg" /> Email
          </label>
          <div class="font-EffraM text-black text-base">
            {{ data.email }}
          </div>
        </div>
        <div class="flex flex-col gap-y-1">
          <label
            for="fullName"
            class="font-EffraM text-base flex items-center gap-x-1"
            style="color: #6d6d6d;"
          >
            <img src="/svg/consultation/info/body-scan.svg" /> État civil
          </label>
          <div class="font-EffraM text-black text-base">
            {{ $FaSituation(data.relationship) || "-" }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-6 w-full"
      style="border: solid 2px #ededed;"
    >
      <div class="flex flex-row gap-x-1">
        <img src="/svg/consultation/info/shield.svg" />
        <h1 class="text-xl font-EffraR text-black">
          Assurance
        </h1>
      </div>

      <hr class="border border-solid border-gray-200" />

      <div class="grid gap-x-3 gap-y-6 grid-cols-1 lg:grid-cols-5">
        <div class="flex flex-col">
          <label for="assurance" class="font-EffraR text-gray-700 text-base"
            >Assurance complémentaire</label
          >
          <div class="text-black font-EffraR text-lg">
            {{
              `${
                data.insurance && data.insurance.name
                  ? data.insurance.name
                  : "--"
              }`
            }}
          </div>
        </div>
        <div class="flex flex-col">
          <label for="cnss" class="font-EffraR text-gray-700 text-base"
            >N° d'affiliation CNSS
          </label>
          <div class="text-black font-EffraR text-lg">
            {{ `${data.CNSS || "--"}` }}
          </div>
        </div>
        <div class="flex flex-col">
          <label for="assurance" class="font-EffraR text-gray-700 text-base">
            Société contractante</label
          >
          <div class="text-black font-EffraR text-lg">
            {{
              data.insurance && data.insurance.company
                ? data.insurance.company
                : "--"
            }}
          </div>
        </div>
        <div class="flex flex-col">
          <label for="assurance" class="font-EffraR text-gray-700 text-base"
            >N° police</label
          >
          <div class="text-black font-EffraR text-lg">
            {{
              data.insurance && data.insurance.numPolice
                ? data.insurance.numPolice
                : "--"
            }}
          </div>
        </div>
        <div class="flex flex-col">
          <label for="adhesion" class="font-EffraR text-gray-700 text-base"
            >N° d'adhésion</label
          >
          <div class="text-black font-EffraR text-lg">
            {{
              data.insurance && data.insurance.numAdhesion
                ? data.insurance.numAdhesion
                : "--"
            }}
          </div>
        </div>
      </div>

      <div
        v-if="false"
        class="flex flex-row gap-x-1 text-base font-EffraM"
        style="color: #8a8a8a;"
      >
        <img src="/svg/consultation/info/x.svg" /> No available data
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style></style>
