<template>
  <section id="Medicament">
    <div
      class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-10"
      :class="hasOpen ? 'min-h-20' : ' h-20'"
    >
      <!-- ---------------------------------------- Header ------------------------------------------------------------>
      <div class="flex justify-between items-center w-full">
        <div class="flex-1 flex flex-row gap-x-1">
          <img src="/svg/consultation/therapy.svg" />
          <h1 class="text-xl font-EffraR text-black">Soins paramédicaux</h1>
        </div>

        <button
          class="bg-transparent border-0 p-0 m-0 cursor-pointer"
          @click="hasOpen = !hasOpen"
        >
          <img
            :src="
              hasOpen
                ? '/svg/consultation/chevron-up.svg'
                : '/svg/consultation/chevron-down.svg'
            "
          />
        </button>
      </div>
      <hr v-if="hasOpen" class="border border-solid border-gray-200" />
      <!-- ---------------------------------------- Content ----------------------------------------------------------->
      <div class="flex flex-col gap-y-6" v-if="hasOpen">
        <template v-if="!loading">
          <!-- ---------------------------------------- Table of certificats ------------------------------------------->
          <div
            class="
              border border-solid border-otgray
              rounded-lg
              p-8
              grid
              gap-x-3
              grid-cols-1
              lg:grid-cols-2
            "
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="flex flex-col gap-y-1">
              <span class="text-sm font-EffraR" style="color: #767676"
                >Nom du soin</span
              >
              <span class="text-base text-black font-EffraM">
                {{ item.title }}
              </span>
            </div>
            <div class="flex flex-row justify-end gap-x-1">
              <button
                class="
                  border-0
                  bg-transparent
                  hover:bg-gray-200
                  cursor-pointer
                  h-12
                  w-12
                  rounded-full
                  flex
                  items-center
                  justify-center
                  button-tooltip
                  group
                  focus:bg-dokBlue-lighter
                "
                data-tooltip="Voir"
                aria-label="Voir"
                title="Voir"
                @click="previewCertif(item)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                  />
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 15c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
                  />
                </svg>
              </button>
              <button
                class="
                  border-0
                  bg-transparent
                  hover:bg-gray-200
                  cursor-pointer
                  h-12
                  w-12
                  rounded-full
                  flex
                  items-center
                  justify-center
                  button-tooltip
                  group
                  focus:bg-dokBlue-lighter
                "
                data-tooltip="Edit"
                aria-label="Edit"
                title="Edit"
                @click="setDataEdit(item)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 3l5 5L8 21H3v-5L16 3z"
                  />
                </svg>
              </button>
              <button
                class="
                  border-0
                  bg-transparent
                  hover:bg-gray-200
                  cursor-pointer
                  h-12
                  w-12
                  rounded-full
                  flex
                  items-center
                  justify-center
                  button-tooltip
                  group
                  focus:bg-dokBlue-lighter
                "
                data-tooltip="Supprimer"
                aria-label="Supprimer"
                title="Supprimer"
                @click="onRemove(item)"
              >
                <svg
                  class="
                    stroke-current
                    text-red-600
                    group-focus:text-dokBlue-ultra
                  "
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 6h18M8 6V4c0-.53.21-1.04.586-1.414C8.96 2.21 9.47 2 10 2h4c.53 0 1.04.21 1.414.586C15.79 2.96 16 3.47 16 4v2m3 0v14c0 .53-.21 1.04-.586 1.414C18.04 21.79 17.53 22 17 22H7c-.53 0-1.04-.21-1.414-.586C5.21 21.04 5 20.53 5 20V6h14z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <!-- ---------------------------------------- Create new certificats & rapports ------------------------------>
          <div class="flex flex-col gap-y-6">
            <h1
              class="
                font-EffraR
                text-black text-xl
                border-0 border-b-2
                pb-2
                border-solid border-grayxx
                uppercase
                w-full
              "
            >
              <div
                class="
                  flex flex-col
                  lg:flex-row
                  justify-start
                  lg:justify-between
                  gap-y-2
                "
              >
                <div
                  class="flex justify-between items-center gap-x-2"
                  v-if="data._id"
                >
                  <div class="flex-1 flex items-center gap-x-2">
                    <img src="/svg/edit.svg" class="h-6" /> Modification du
                    certificat
                  </div>
                  <dok-button bg="rose" @click.native="resetForm"
                    >Restaurer</dok-button
                  >
                </div>
                <div class="flex items-center gap-x-2">
                  <img src="/svg/file-plus.svg" class="h-6" /> Choisir type de
                  soins
                </div>
              </div>
            </h1>
            <!-- ---------------------------------------- Drop type soin ---------------------------------------------->
            <div class="flex">
              <div class="flex w-full">
                <dok-select
                  label="Choisir type de soin"
                  size="md"
                  :d-model.sync="data.selectedModelCat"
                  :dok-disabled="disbledDropdown"
                >
                  <template v-slot:rightIcon>
                    <img src="/svg/chevron-down.svg" />
                  </template>
                  <template v-slot:options>
                    <option
                      class="text-gray-600"
                      :value="null"
                      selected
                      disabled
                    >
                      Choisir type de soin
                    </option>
                    <!-- <optgroup
                    v-for="(listIem, key) in listPreviewObj"
                    :key="key"
                    :label="
                      key == 'global' ? 'Modèles prédéfinis' : 'Mes modèles'
                    "
                  > -->
                    <option
                      v-for="(item, index) in listPreview"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                    <!-- </optgroup> -->
                  </template>
                </dok-select>
              </div>
            </div>
            <!-- ---------------------------------------- Drop Model -------------------------------------------------->
            <div
              v-if="data.selectedModelCat"
              class="flex flex-row justify-between items-end"
              :class="selectedModelDoctorId == doctor._id ? 'gap-x-5' : ''"
            >
              <div class="flex-1">
                <dok-select
                  label="Choisir un modèle"
                  d-placeholder="Choisir un modèle"
                  size="md"
                  :d-model.sync="data.selectedModel"
                  :dok-disabled="disbledDropdown"
                >
                  <template v-slot:rightIcon>
                    <img src="/svg/chevron-down.svg" />
                  </template>
                  <template v-slot:options>
                    <option class="text-gray-400" :value="null" selected>
                      -- Choisir un modèle
                    </option>

                    <option
                      v-for="(item, index) in listSubModels"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                    <option
                      value="create_new_model"
                      v-if="data.selectedModelCat"
                    >
                      -- Créer un nouveau modèle
                    </option>
                  </template>
                </dok-select>
              </div>
              <div class="flex">
                <dok-button
                  type="button"
                  bg="blue"
                  size="md"
                  customClass="mr-1"
                  @click.native="openModelEdit(selectedModelDoctorId)"
                  v-if="selectedModelDoctorId == doctor._id"
                  >Edit le Modèle
                </dok-button>
              </div>
            </div>
            <!-- ---------------------------------------- Editor ------------------------------------------------------>
            <quill-editor
              :content="data.content"
              placeholder="Contenu du Certificat"
              :options="editorOptions"
              @change="onEditContent($event)"
            />
            <!-- ---------------------------------------- Buttons -------------------------------------------------- -->
            <div
              class="flex flex-col lg:flex-row lg:justify-end gap-y-4 gap-x-6"
            >
              <dok-button
                size="lg"
                bg="bluelighter"
                custom-class="px-3 flex items-center gap-x-2"
                @click.native="data._id ? onUpdate() : onSave()"
              >
                <img src="/svg/check_blue.svg" /> Sauvegarder
              </dok-button>
              <dok-button
                size="lg"
                custom-class="px-3 flex items-center gap-x-2"
                @click.native="onGenere"
              >
                <img src="/svg/consultation/sent.svg" /> Générer le certificat
              </dok-button>
            </div>
          </div>
        </template>
        <!-- ---------------------------------------- Loader ------------------------------------------------------- -->
        <template v-if="loading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="margin: auto; display: block; shape-rendering: auto"
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle cx="30" cy="50" fill="#fd5b68" r="20">
              <animate
                attributeName="cx"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;0.5;1"
                values="30;70;30"
                begin="-0.5s"
              ></animate>
            </circle>
            <circle cx="70" cy="50" fill="#2e6bff" r="20">
              <animate
                attributeName="cx"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;0.5;1"
                values="30;70;30"
                begin="0s"
              ></animate>
            </circle>
            <circle cx="30" cy="50" fill="#fd5b68" r="20">
              <animate
                attributeName="cx"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;0.5;1"
                values="30;70;30"
                begin="-0.5s"
              ></animate>
              <animate
                attributeName="fill-opacity"
                values="0;0;1;1"
                calcMode="discrete"
                keyTimes="0;0.499;0.5;1"
                dur="1s"
                repeatCount="indefinite"
              ></animate>
            </circle>
          </svg>
        </template>
      </div>
    </div>
    <!-- ----------------------------------------------- Modal ----------------------------------------------------- -->
    <modal
      :isActive="modalCertifModal.is"
      :exitModal="exitModalCertifModal"
      :width="600"
      :maxHeight="650"
      :title="selectModelCategory ? selectModelCategory.name : ''"
    >
      <paramedical_model
        ref="modale_certif"
        :certifModel="editModel"
        v-on:refreshListPreview="refreshListPreview"
      ></paramedical_model>
    </modal>
  </section>
</template>
<script>
  import _ from "lodash";
  import moment from "moment";
  import { EventBus } from "@/event-bus";
  import { mapActions } from "vuex";
  import paramedical_model from "./paramedical_model.vue";
  import modal from "@/views/global-components/dragModal";
  import { quillEditor } from "vue-quill-editor";
  import "quill/dist/quill.core.css";
  import "quill/dist/quill.snow.css";

  export default {
    props: {
      patient: {
        type: Object,
        required: true,
      },
    },
    components: {
      quillEditor,
      paramedical_model,
      modal,
    },
    data() {
      return {
        doctor: this.$auth.getUserInfo(),
        loading: true,
        hasOpen: false,
        stopWatch: false,
        listPreview: [],
        listPreviewObj: {
          my_models: [],
          global: [],
        },
        listSubModels: [],
        list: [],
        disbledDropdown: false,
        selectModelCategory: null,
        data: {
          selectedModelCat: null,
          content: null,
          selectedModel: null,
          name: null,
        },
        editorOptions: {
          theme: "snow",
          placeholder: "Saisissez votre texte ...",
          modules: {
            toolbar: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["bold", "italic", "underline", "strike"],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ align: [] }],
              [{ color: [] }, { background: [] }],
              ["link", "image"],
            ],
          },
        },
        modalCertifModal: {
          is: false,
        },
        selectedModelId: null,
        selectedModelDoctorId: null,
        editModel: {},
      };
    },
    watch: {
      "data.selectedModelCat"(newVal, oldVal) {
        if (this.stopWatch) {
          return setTimeout(() => {
            this.stopWatch = false;
          }, 1000);
        }

        if (newVal && newVal !== oldVal) {
          let findModel = this.listPreview.find((item) => item._id === newVal);
          if (!findModel) {
            console.log("Canon't Find model");
            return;
          }
          this.selectModelCategory = findModel;
          this.getlistSubModels(newVal);
          this.data.Catcontent = findModel.model;
          this.data.name = findModel.name;
          const _this = this;
          // this.$nextTick(() => {
          //   _this.replaceModel();
          // });
        }
      },
      "data.selectedModel"(newVal, oldVal) {
        if (this.stopWatch) {
          return setTimeout(() => {
            this.stopWatch = false;
          }, 1000);
        }

        if (newVal !== oldVal) {
          this.selectedModelDoctorId = null;
          if (!newVal) {
            this.data.content = "";
            this.data.name = "";
            return;
          }
          if (newVal === "create_new_model") {
            this.createNewModel();
            return;
          } else {
            let findModel = this.listSubModels.find(
              (item) => item._id === newVal
            );
            if (!findModel) {
              console.log("Canon't Find model");
              return;
            }
            this.data.content = findModel.model;
            this.data.name = findModel.name;

            this.$nextTick(() => {
              this.selectedModelDoctor();
              this.replaceModel();
            });
          }
        }
      },
    },
    created() {
      this.getMyList();
    },
    methods: {
      ...mapActions({
        getConsultationCertificates:
          "consultation/GET_SOINS_PARA_MEDICAL_CONSULTATION",
      }),
      async getMyList() {
        await this.getConsultationCertificates({
          consultationId: this.$route.params.id,
          onData: ({ ok, data }) => {
            if (!ok) {
              return;
            }
            this.list = data;
            this.getListModels();
          },
        });
      },
      async getListModels() {
        await this.$store.dispatch("consultation/GET_SOINS_PARA_MEDICAL_MODELS", {
          onData: ({ ok, data }) => {
            if (ok) {
              this.listPreview = data;
              this.listPreviewObj = {
                my_models: [],
                global: [],
              };
              for (var i = 0; i < data.length; i++) {
                if (data[i].doctor == this.doctor._id) {
                  this.listPreviewObj["my_models"].push(data[i]);
                } else {
                  this.listPreviewObj["global"].push(data[i]);
                }
              }
              this.$nextTick(() => {
                this.loading = false;
              });
            }
          },
        });
      },
      async getlistSubModels(modelId, aFuction) {
        await this.$store.dispatch(
          "consultation/GET_SOINS_PARA_MEDICAL_MY_MODELS",
          {
            modelId: modelId,
            onData: ({ ok, data }) => {
              if (ok) {
                this.listSubModels = data;
                if (aFuction) {
                  aFuction();
                }
              }
            },
          }
        );
      },
      async onSave() {
        if (!this.data.content || !this.data.name) {
          return;
        }
        this.$vs.loading();
        const { ok, data, message } = await this.$store.dispatch(
          "consultation/SAVE_PARAMEDICAL_SOIN_FOR_PATIENT",
          {
            consultationId: this.$route.params.id,
            params: {
              content: this.data.content,
              title: this.data.name,
              typeSoin: this.selectModelCategory,
            },
          }
        );
        if (!ok) {
          this.$vs.notify({
            time: 4000,
            text: message,
            color: ok ? "success" : "danger",
          });
          return this.$vs.loading.close();
        }
        this.list.push(data);
        this.data = {
          content: null,
          selectedModel: null,
          name: null,
        };

        this.$vs.loading.close();
        return data;
      },
      async onUpdate() {
        if (!this.data._id) {
          return console.log(
            this.data,
            "Nous n'arrivons pas à mettre à jour, essayez plus tard"
          );
        }

        this.$vs.loading();
        await this.$store.dispatch(
          "consultation/UPDATE_PARAMEDICAL_SOIN_FOR_PATIENT",
          {
            patientSoinId: this.data._id,
            params: {
              content: this.data.content,
              title: this.data.name,
            },
            onData: ({ ok, message }) => {
              if (!ok) {
                this.$vs.loading.close();
                return console.log(
                  "Nous n'arrivons pas à mettre à jour, essayez plus tard"
                );
              }
              this.getMyList();
              this.data = {
                content: null,
                selectedModel: null,
                name: null,
              };

              this.disbledDropdown = false;
              this.$vs.loading.close();
            },
          }
        );
      },
      async onRemove(item) {
        if (!item._id) {
          return console.log(
            "Nous n'arrivons pas à trouver l'élément, essayez plus tard"
          );
        }
        EventBus.$emit("flashDok", {
          defualtTime: 30000,
          type: "delete",
          title: "Suppression de certificat",
          message: "Êtes-vous surs de vouloir supprimer ce certificat ?",
          successmessage: "Rendez-vous supprimé avec succès",
          confirm: async () => {
            this.$vs.loading();
            await this.$store.dispatch(
              "consultation/REMOVE_PARAMEDICAL_SOIN_FOR_PATIENT",
              {
                patientSoinId: item._id,
                onData: (itemID, { data, ok, message }) => {
                  if (ok) {
                    this.list = this.list.filter((i) => i._id !== item._id);
                  } else {
                    this.$vs.notify({
                      time: 4000,
                      text: message,
                      color: ok ? "success" : "danger",
                    });
                  }
                  this.$vs.loading.close();
                },
              }
            );
          },
          cancel: (callback) => {
            return callback();
          },
        });
      },
      async onGenere() {
        let dataSave = {};

        if (!this.data._id) {
          dataSave = await this.onSave();
        }

        if (
          typeof dataSave === "undefined" &&
          typeof this.data._id === "undefined"
        ) {
          return console.log("Ash Tatrwan");
          return;
        }

        this.$vs.loading();
        await this.$store.dispatch("consultation/GENERATE_PARAMEDICAL_SOIN", {
          paraSoinId: this.data._id ? this.data._id : dataSave._id,
          onData: ({ ok, data, message }) => {
            this.$vs.notify({
              time: 4000,
              text: message,
              color: ok ? "success" : "danger",
            });

            this.data = {
              content: null,
              selectedModel: null,
              name: null,
            };

            this.$vs.loading.close();
          },
        });
      },
      async setDataEdit(item) {
        if (!item._id) {
          return console.log(
            "Nous n'arrivons pas à trouver cet élément, essayez plus tard"
          );
        }

        await this.getlistSubModels(item.typeSoin, () => {
          this.data.selectedModelCat = item.typeSoin;

          let findModelByName = this.listSubModels.find((it) => {
            return it.name === item.title;
          });
          if (findModelByName) {
            this.data.selectedModel = findModelByName._id;
            this.stopWatch = true;
          }
        });

        this.data.content = item.content;
        this.data._id = item._id;
        this.data.name = item.title;

        this.disbledDropdown = true;
      },
      async refreshListPreview(model) {
        this.data.selectedModel = null;
        this.data.content = "";
        await this.getlistSubModels(this.selectModelCategory._id);
        if (model) {
          this.data.selectedModel = model._id;
        }
        this.exitModalCertifModal();
      },
      exitModalCertifModal() {
        this.modalCertifModal.is = false;
      },
      resetForm() {
        let confirmAlert = confirm("Êtes-vous sûrs ?");
        if (confirmAlert) {
          this.data = {
            content: null,
            selectedModel: null,
            name: null,
          };
          this.disbledDropdown = false;
        }
      },
      onEditContent(event) {
        if (event.html) this.data.content = event.html;
      },
      replaceModel(model) {
        const GENRES = {
          MALE: "Homme",
          FEMALE: "Femme",
        };
        this.data.content = this.data.content
          .replace("[[today_date]]", moment().format("DD/MM/YYYY"))
          .replace(
            "[[doctor_city]]",
            this.doctor.landingPage.address.city
              ? this.doctor.landingPage.address.city.name
              : "[[doctor_city]]"
          )
          .replace(
            "[[doctor_name]]",
            this.doctor.firstName.toUpperCase() +
              " " +
              this.doctor.lastName.toUpperCase()
          )
          .replace(
            "[[patient_name]]",
            this.patient.firstName.toUpperCase() +
              " " +
              this.patient.lastName.toUpperCase()
          )
          .replace(
            "[[patient_birthday]]",
            this.patient.birthday
              ? moment(this.patient.birthday).format("DD/MM/YYYY")
              : "[ _ ]"
          )
          .replace(
            "[[patient_cin]]",
            this.patient.cin ? this.patient.cin : "[ _ ]"
          )
          .replace(
            "[[patient_sexe]]",
            this.patient.gender ? GENRES[this.patient.gender] : " [ _ ]"
          );
      },
      selectedModelDoctor() {
        for (var i = 0; i < this.listSubModels.length; i++) {
          if (this.listSubModels[i]._id == this.data.selectedModel) {
            this.selectedModelDoctorId = this.listSubModels[i].doctor;
            this.selectedModelId = this.data.selectedModel;
            break;
          }
        }
      },
      createNewModel() {
        this.editModel = {
          parentSoin: this.selectModelCategory._id,
        };
        this.$nextTick(() => {
          this.modalCertifModal.is = true;
        });
      },
      openModelEdit() {
        for (var i = 0; i < this.listSubModels.length; i++) {
          if (this.listSubModels[i]._id == this.selectedModelId) {
            this.editModel = this.listSubModels[i];
            this.$nextTick(() => {
              this.modalCertifModal.is = true;
            });
            break;
          }
        }
      },
      previewCertif(item) {
        window.open(
          `${process.env.VUE_APP_BASE_URL}/acts/soins-paramedicaux/${
            item._id
          }/preview?token=${this.$auth.getToken()}`,
          "_blank"
        );
      },
    },
  };
</script>

<style>
select option:first-child {
  color: green;
}
</style>
