<template>
  <div>
    <div
      class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-10"
      :class="isActiveToggle ? 'min-h-20' : ' h-20'"
      style="border: solid 2px #ededed;"
    >
      <template v-if="!loading">
        <div class="flex justify-between items-center">
          <div class="flex-1 flex flex-row items-center gap-x-1">
            <img src="/svg/user_plus.svg" />
            <h1 class="text-xl font-EffraR text-black">
              Suivi médical
            </h1>
          </div>

          <toggle :is-active.sync="isActiveToggle"></toggle>
        </div>

        <div class="flex flex-col gap-y-6" v-if="isActiveToggle">
          <div class="grid gap-x-4 gap-y-4 grid-cols-1 lg:grid-cols-2">
            <div
              class="flex justify-between gap-x-4 rounded-cu p-5"
              style="border: solid 1px #eaeaea;"
            >
              <span class="font-EffraM text-black text-base"
                >Démarrer le suivi médical dans</span
              >
              <div class="relative">
                <select
                  class="border-0 bg-transparent text-base font-EffraM text-dokBlue-ultra"
                  @change="onStartDateChange"
                >
                  <option value="1">24 heures</option>
                  <option value="2">48 heures</option>
                  <option value="3">72 heures</option>
                </select>
              </div>
            </div>
            <div
              class="flex justify-between gap-x-4 rounded-cu p-5"
              style="border: solid 1px #eaeaea;"
            >
              <span class="font-EffraM text-black text-base">Fréquence</span>
              <div class="relative">
                <select
                  class="border-0 bg-transparent text-base font-EffraM text-dokBlue-ultra"
                  v-model.number="config.frequency"
                >
                  <option value="1">Tous les jours</option>
                  <option value="2">Chaque 2 jours</option>
                  <option value="3">Chaque 3 jours</option>
                </select>
              </div>
            </div>
            <!------------------------------  Date  -------------------------------->
            <div
              class="flex justify-between gap-x-4 rounded-cu p-5"
              style="border: solid 1px #eaeaea;"
            >
              <span class="font-EffraM text-black text-base"
                >Date de début</span
              >
              <div class="relative">
                <dok-input-date
                  :d-model.sync="config.startDate"
                  size="lg"
                  format="DD-MM-YYYY"
                ></dok-input-date>
              </div>
            </div>
            <div
              class="flex justify-between gap-x-4 rounded-cu p-5"
              style="border: solid 1px #eaeaea;"
            >
              <span class="font-EffraM text-black text-base">Date de fin</span>
              <div class="relative">
                <dok-input-date
                  :d-model.sync="config.endDate"
                  size="lg"
                  format="DD-MM-YYYY"
                ></dok-input-date>
              </div>
            </div>
          </div>

          <div class="flex justify-end">
            <dok-button
              size="md"
              custom-class="flex items-center gap-x-2"
              @click.native="onSaveSMA"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M20 6L9 17l-5-5"
                />
              </svg>
              Sauvegarder</dok-button
            >
          </div>
        </div>
      </template>

      <template v-if="loading">
        <div class="bg-grayxx w-full h-6 rounded animate-pulse"></div>
      </template>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus/index.js";
import { mapActions } from "vuex";
import { clone } from "lodash";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

// Components
import toggle from "@/views/dashboard/consultationsN/globalComponents/switchRadio.vue";

export default {
  data() {
    return {
      loading: true,
      is: false,
      consultation: {},
      config: {
        startDate: moment().format("DD-MM-YYYY"),
        endDate: moment().format("DD-MM-YYYY"),
        frequency: null
      }
    };
  },
  created() {
    this.getConsultaion({
      id: this.$route.params.id,
      onData: ({ data }) => {
        this.consultation = data;
        this.isActiveToggle = data.hasFollowUp;
        this.loading = false;
      }
    });

    if (this.is) {
      this.getSMAConfig();
    }
  },
  methods: {
    ...mapActions({
      getConfig: "consultation/GET_SMA_CONFIG",
      deleteConfig: "consultation/DELETE_SMA_CONFIG",
      updateConfig: "consultation/UPDATE_SMA_CONFIG",
      getConsultaion: "consultation/GET_BY_ID"
    }),
    onSaveSMA() {
      this.$vs.loading();

      let serializedConfig = clone(this.config);
      serializedConfig.startDate = moment(
        this.config.startDate,
        "DD-MM-YY"
      ).toISOString();

      serializedConfig.endDate = moment(
        this.config.endDate,
        "DD-MM-YY"
      ).toISOString();

      // return //console.log(this.config);
      this.updateConfig({
        consultationId: this.$route.params.id,
        config: this.config,
        onData: ({ ok }) => {
          EventBus.$emit("flashDok", {
            type: ok ? "success" : "error",
            message: ok
              ? "Enregistré avec succès"
              : "L'enregistrement n'a pas réussi",
            timerAlert: 8000
          });

          this.$vs.loading.close();
        }
      });
    },
    onStartDateChange() {
      const newDate = moment(this.config.startDate, "DD-MM-YYYY")
        .add(event.target.value, "days")
        .format("DD-MM-YYYY");

      this.config.startDate = newDate;
    },
    deleteSMAConfig() {
      this.deleteConfig({
        consultationId: this.$route.params.id,
        onData: () => {}
      });
    },
    getSMAConfig() {
      this.getConfig({
        consultationId: this.$route.params.id,
        onData: ({ ok, data }) => {
          if (ok) {
            this.config = data;
            this.$nextTick(() => {
              console.log(this.config);
            });
            this.$forceUpdate();
          }
        }
      });
    }
  },
  computed: {
    isActiveToggle: {
      get() {
        return this.is;
      },
      set(value) {
        if (!value) {
          this.deleteSMAConfig();
        }

        if (value) {
          this.getSMAConfig();
        }

        this.is = value;
      }
    }
  },
  components: { toggle, DatePicker }
};
</script>

<style></style>
