<template>
  <section
    id="consultation"
    class="h-full flex flex-col gap-y-3 lg:gap-y-4 bg-[#f0f0f0]"
    :class="loader ? 'items-center justify-center' : ''"
  >
    <template v-if="!loader && !noSubscribed && patient">
      <!-- <div
        class="bg-bgGrey fixed lg:hidden bottom-0 left-0 right-0 z-1000 px-2"
        :class="
          dropdownMobile ? 'h-500 overflow-y-scroll' : 'h-16 flex items-center'
        "
      >
        <div
          class="flex justify-between items-center w-full"
          :class="dropdownMobile ? 'py-5' : 'py-0'"
        >
          <h1 class="text-black font-EffraR text-xl flex-1">
            Patient More Informations
          </h1>
          <dok-button
            bg="none"
            @click.native="
              () => {
                dropdownMobile = !dropdownMobile;
              }
            "
          >
            <img src="/svg/chevron-down.svg" />
          </dok-button>
        </div>
        <left-bolck v-if="dropdownMobile" :patient="patient"></left-bolck>
      </div> -->

      <!-- Patient Header -->
      <header
        class="bg-white w-full px-1 lg:px-13 flex flex-col gap-y-8 py-2 z-2"
      >
        <div class="flex flex-col gap-y-3">
          <div class="flex flex-row items-center gap-x-2">
            <h1 class="font-EffraM text-2xl">
              {{ `${patient.firstName} ${patient.lastName}` }}
            </h1>

            <template v-if="!hasLoadingConsultation">
              <img
                src="/svg/video_ssm.svg"
                v-if="consultation.type === 'VIDEO-CONSULTATION'"
              />
              <dok-button
                v-if="consultation.type !== 'VIDEO-CONSULTATION'"
                bg="rose"
                size="sm"
                custom-class="text-sm font-EffraM"
                @click.native="switchConsultationToVideo"
              >
                Changer en téléconsultation
              </dok-button>
            </template>
          </div>

          <div class="flex flex-row justify-between items-end">
            <div
              class="
                flex flex-col
                lg:flex-row
                gap-x-4 gap-y-2
                items-start
                lg:items-center
                flex-1
              "
            >
              <div class="flex flex-row gap-x-2 items-center">
                <img src="/svg/calendar_ssm.svg" />
                <span
                  class="font-EffraM text-black text-base whitespace-no-wrap"
                >
                  {{
                    _moment()(consultation.startAt)
                      .locale("fr")
                      .format("DD MMM YYYY")
                  }}
                  -
                  {{ consultation.startAt | moment("HH:mm") }}
                </span>
              </div>
              <div class="flex flex-row gap-x-2 items-center">
                <span
                  class="
                    font-EffraM
                    text-black text-base
                    flex
                    items-center
                    gap-x-1
                  "
                >
                  <div
                    class="h-4 w-4 rounded-full"
                    :style="`background: ${
                      consultation.category && consultation.category.color
                        ? consultation.category.color
                        : '#000'
                    };`"
                  ></div>
                  {{
                    (consultation.category && consultation.category.title) ||
                    "--"
                  }}
                </span>
              </div>
              <span
                class="p-1 rounded font-EffraM text-sm"
                style="background: #eeffee; color: #0d280d"
              >
                {{ $statusConsultation(consultation.status) }}
              </span>
            </div>
            <div class="flex lg:hidden gap-x-2">
              <button
                class="
                  py-3
                  rounded
                  px-3
                  bg-transparent
                  border-0
                  text-base
                  font-EffraM
                "
                :class="
                  currentPage > 1 ? 'cursor-pointer' : 'cursor-not-allowed'
                "
                v-if="currentPage > 1"
                @click="currentPage > 1 ? navFooterClick('back') : () => {}"
              >
                Retour
              </button>
              <button
                class="
                  py-3
                  rounded
                  px-3
                  bg-transparent
                  border-0
                  text-base
                  font-EffraM
                  bg-dokBlue-lighterr
                  text-dokBlue-ultra
                "
                :class="
                  currentPage < 5 ? 'cursor-pointer' : 'cursor-not-allowed'
                "
                v-if="currentPage < 5"
                @click="currentPage < 5 ? navFooterClick('next') : () => {}"
              >
                Suivant
              </button>
              <button
                v-if="currentPage === 4"
                :disabled="consultation.state === 'DONE'"
                :class="
                  consultation.state === 'DONE'
                    ? 'bg-white border border-solid border-nblue text-nblue py-3 rounded-cu cursor-not-allowed px-3 font-bold whitespace-no-wrap'
                    : 'bg-nblue text-white py-3 rounded-cu cursor-pointer px-3 font-bold whitespace-no-wrap border-0'
                "
                @click="onDoneConsultationCalled"
              >
                {{
                  consultation.state === "DONE"
                    ? "Consultation terminée"
                    : "Terminer la consultation"
                }}
              </button>
            </div>
          </div>
        </div>
      </header>

      <!-- Content  -->
      <main class="flex-1 overflow-hidden mb-[50px] lg:mb-0">
        <div class="flex flex-row gap-x-4 w-full h-full px-5">
          <!-- leftBlock -->
          <div
            v-if="currentPage !== 1"
            class="
              fixed
              lg:static
              w-full
              lg:w-1/8377
              bottom-0
              left-0
              right-0
              shadow-navbottom
              lg:shadow-none
              bg-sidebar
              lg:bg-transparent
              z-10
              overflow-y-auto
              scroll-dok
              pr-3
            "
          >
            <div class="flex lg:hidden items-center justify-between py-3 px-3">
              <div class="flex flex-row gap-x-2 items-center">
                <img
                  :src="$checkAvatar(patient.avatar)"
                  class="h-[45px] w-[45px] object-cover rounded-full"
                />
                <h1 class="font-EffraM text-lg">
                  {{ `${patient.firstName} ${patient.lastName}` }}
                </h1>
              </div>
              <button
                class="bg-transparent border-0 cursor-pointer"
                @click="dropdownMobile = !dropdownMobile"
              >
                <i
                  :class="
                    dropdownMobile ? 'fas fa-chevron-down' : 'fas fa-chevron-up'
                  "
                  class="hover:text-dokBlue-ultra"
                ></i>
              </button>
            </div>
            <div
              :class="
                dropdownMobile
                  ? 'h-300 lg:h-full px-2 lg:px-0'
                  : 'h-auto lg:h-full px-2 lg:px-0'
              "
            >
              <div
                :class="
                  dropdownMobile
                    ? 'block lg:block pb-3 lg:pb-0'
                    : 'hidden lg:block'
                "
              >
                <left-bolck
                  :patient="patient"
                  :conversationIdHash="consultation.conversationIdHash || ''"
                  :conversationId="consultation.conversationId || ''"
                  :type="consultation.type"
                />
              </div>
            </div>
          </div>

          <!-- Patient Informations & Helthbook -->
          <template v-if="currentPage === 1">
            <div
              class="
                flex flex-col
                gap-y-6
                w-full
                overflow-y-auto
                scroll-dok
                pr-3
              "
            >
              <!-- Current Patient Informations -->
              <dok-patient-info :data="patient" />

              <!-- Historque De Consultation -->
              <historique-consultations :patient="patient" />

              <!-- Helthbook -->
              <h1
                class="
                  text-xl
                  font-EffraR font-medium
                  text-black
                  flex
                  items-center
                  gap-x-1
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="#111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1.667 2.5h5c.884 0 1.732.351 2.357.976.625.625.976 1.473.976 2.357V17.5c0-.663-.263-1.299-.732-1.768C8.8 15.263 8.163 15 7.5 15H1.667V2.5zM18.333 2.5h-5c-.884 0-1.732.351-2.357.976C10.351 4.101 10 4.95 10 5.833V17.5c0-.663.263-1.299.732-1.768.47-.469 1.105-.732 1.768-.732h5.833V2.5z"
                  />
                </svg>
                Carnet de santé
              </h1>
              <dok-helth-book :patient="patient" />
            </div>
          </template>

          <!-- Other Sections -->
          <template v-if="currentPage !== 1">
            <div
              class="
                flex
                gap-x-5
                h-full
                w-full
                overflow-y-auto
                scroll-dok
                pr-3
                mb-10
                lg:mb-0
              "
            >
              <template v-if="currentPage === 2">
                <dok-observation-evaluation class="w-full" :patient="patient" />
              </template>
              <template v-if="currentPage === 3">
                <dok-ordonnance class="w-full" :patient="patient" />
              </template>
              <template v-if="currentPage === 4">
                <dok-compte-rendu class="w-full" :patient="patient" />
              </template>
              <template v-if="currentPage === 5">
                <dok-documents class="w-full" :patient="patient" />
              </template>
            </div>
          </template>
        </div>
      </main>

      <!-- Footer -->
      <footer
        class="
          bg-white
          w-full
          h-[72px]
          px-1
          hidden
          lg:flex
          items-center
          justify-end
          gap-x-5
          pr-10
          w-full
        "
      >
        <button
          class="
            py-3
            rounded
            px-10
            bg-transparent
            border-0
            text-base
            font-EffraM
          "
          :class="currentPage > 1 ? 'cursor-pointer' : 'cursor-not-allowed'"
          @click="currentPage > 1 ? navFooterClick('back') : () => {}"
        >
          Retour
        </button>
        <button
          class="
            py-3
            rounded
            px-10
            border-0
            text-base
            font-EffraM
            bg-dokBlue-lighterr
            text-dokBlue-ultra
          "
          :class="currentPage < 5 ? 'cursor-pointer' : 'cursor-not-allowed'"
          @click="currentPage < 5 ? navFooterClick('next') : () => {}"
        >
          Suivant
        </button>

        <button
          v-if="currentPage === 4"
          :disabled="consultation.state === 'DONE'"
          :class="
            consultation.state === 'DONE'
              ? 'bg-white border border-solid border-nblue text-nblue py-3 rounded-cu cursor-not-allowed px-3 font-bold whitespace-no-wrap'
              : 'bg-nblue text-white py-3 rounded-cu cursor-pointer px-3 font-bold whitespace-no-wrap border-0'
          "
          @click="onDoneConsultationCalled"
        >
          {{
            consultation.state === "DONE"
              ? "Consultation terminée"
              : "Terminer la consultation"
          }}
        </button>
      </footer>

      <!------------------------------------------MODAL ONBOARDING----------------------------------------------->
      <dok-modal
        :isActive="modal.is"
        :exitModal="() => {}"
        :hiddenHeader="true"
        :hasNoScroll="true"
        :width="350"
      >
        <OnbordingPatient v-if="currentPage === 1" />
        <OnbordingObservation v-if="currentPage === 2" />
        <OnbordingOrdonnances v-if="currentPage === 3" />
        <OnbordingCompteRendu v-if="currentPage === 4" />
        <OnbordingDocument v-if="currentPage === 5" />

        <!-- <OnbordingSuviMedical v-if="currentPage === 6" /> -->
      </dok-modal>
    </template>

    <!-- Loader  -->
    <template v-if="loader">
      <div class="w-336 mx-auto px-5">
        <div
          class="
            bg-white
            shadow
            rounded-cu
            p-5
            px-8
            flex
            justify-center
            gap-x-8
          "
        >
          <div
            class="
              bg-dokBlue-light
              w-8
              h-8
              rounded-full
              animate-bounce
              blue-circle
            "
          ></div>
          <div
            class="
              bg-rosDok-ultra
              w-8
              h-8
              rounded-full
              animate-bounce
              red-circle
            "
          ></div>
          <div
            class="
              bg-dokBlue-ultra
              w-8
              h-8
              rounded-full
              animate-bounce
              green-circle
            "
          ></div>
        </div>
      </div>
    </template>
    <template v-if="noSubscribed">
      <div
        class="
          w-full
          lg:w-11/96 lg:mx-auto
          bg-white
          rounded-none
          lg:rounded-lg
          p-5
          flex flex-col
          gap-y-8
          mt-20
        "
      >
        <div class="flex flex-col gap-y-0 lg:gap-y-6">
          <div class="mx-auto px-5 font-black text-3xl">
            {{ noSubscribedMessage }}
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { EventBus } from "@/event-bus";
import moment from "moment";

import leftBolck from "./globalComponents/leftBlock.vue";
import historiqueConsultations from "@/views/dashboard/consultationsN/globalComponents/historiqueConsultations.vue";

// Components
import dokOrdonnance from "./ordonnances_certificats/index.vue";
import dokDocuments from "./documents/index.vue";
import dokObservationEvaluation from "./observations_evaluation/index.vue";
import dokPatientInfo from "./information_patient/index.vue";
import dokCompteRendu from "./compte_rendu/index.vue";
import dokHelthBook from "./carnet_sante/index.vue";
import dokModal from "@/views/global-components/modal";

// Onboarding
import OnbordingPatient from "./onboarding/patient_info.vue";
import OnbordingHelthbook from "./onboarding/helthbook.vue";
import OnbordingOrdonnances from "./onboarding/ordonnances_certificats.vue";
import OnbordingSuviMedical from "./onboarding/suvi_medical.vue";
import OnbordingDocument from "./onboarding/document.vue";
import OnbordingCompteRendu from "./onboarding/compte_rendu.vue";
import OnbordingObservation from "./onboarding/observations.vue";

export default {
  components: {
    historiqueConsultations,
    leftBolck,
    dokOrdonnance,
    dokDocuments,
    dokObservationEvaluation,
    dokPatientInfo,
    dokCompteRendu,
    dokHelthBook,
    dokModal,
    // Onbording Components
    OnbordingPatient,
    OnbordingHelthbook,
    OnbordingOrdonnances,
    OnbordingSuviMedical,
    OnbordingDocument,
    OnbordingCompteRendu,
    OnbordingObservation,
  },
  data() {
    return {
      loader: true,
      currentPage: 0,
      consultationID: this.$route.params.id,
      patient: null,
      consultation: {},
      dropdownMobile: false,
      modal: { is: false },
      noSubscribed: false,
      noSubscribedMessage:
        "Vous ne pouvez plus accéder à cette rubrique, Merci de renouveler votre abonnement",
      user: {},
      nav: [
        {
          id: 1,
          name: "Patient",
          icon: "/svg/nav/user.svg",
        },
        // {
        //   id: 2,
        //   name: "Carnet de sante",
        //   icon: "/svg/nav/book-open.svg"
        // },
        {
          id: 3,
          name: "Consultation",
          icon: "/svg/nav/edit-2.svg",
        },
        {
          id: 4,
          name: "Ordonnances et certificats",
          icon: "/svg/nav/paperclip.svg",
        },
        {
          id: 5,
          name: "Compte rendu",
          icon: "/svg/nav/file-text.svg",
        },
        {
          id: 7,
          name: "Documents générés",
          icon: "/svg/nav/folder.svg",
        },
        // {
        //   id: 6,
        //   name: "Suivi médical augmenté",
        //   icon: "/svg/nav/eye.svg"
        // }
      ],
      hasLoadingConsultation: true,
    };
  },
  watch: {
    currentPage(nextPage, lastPage) {
      // ONBORDING IS DISBLED .............
      // let onbordingLocalStorage = JSON.parse(
      //   localStorage.getItem("onbording_consultation")
      // );
      // if (!onbordingLocalStorage.patient && this.currentPage === 1) {
      //   this.modal.is = true;
      // }
      // if (!onbordingLocalStorage.observation && this.currentPage === 2) {
      //   this.modal.is = true;
      // }
      // if (!onbordingLocalStorage.ordonnaces && this.currentPage === 3) {
      //   this.modal.is = true;
      // }
      // if (!onbordingLocalStorage.compteRendu && this.currentPage === 4) {
      //   this.modal.is = true;
      // }
      // if (!onbordingLocalStorage.documents && this.currentPage === 5) {
      //   this.modal.is = true;
      // }
      // if (!onbordingLocalStorage.suiviMedical && this.currentPage === 6) {
      //   this.modal.is = true;
      // }
    },
  },
  mounted() {
    EventBus.$on("CONSULTATION_TO_PAGE", (num) => {
      if (num) {
        this.currentPage = num;
      }
    });
    this.user = this.$auth.getUserInfo();
    if (1 || !this.user.payment || !this.user.payment.done) {
      this.noSubscribed = this.$auth.isNotSubscribed();

      this.$nextTick(() => {
        this.loader = false;
      });
    }

    EventBus.$on("CLOSR_ONBORDING_MODAL", () => {
      this.modal.is = false;
    });
  },
  created() {
    this.currentPage = 1;
    // let onbordingLocalStorage = localStorage.getItem("onbording_consultation");

    // if (!onbordingLocalStorage) {
    //   localStorage.setItem(
    //     "onbording_consultation",
    //     JSON.stringify({
    //       patient: false,
    //       helthbook: false,
    //       observation: false,
    //       ordonnaces: false,
    //       suiviMedical: false,
    //       documents: false,
    //       compteRendu: false
    //     })
    //   );
    // }

    this.getPatientInfo();
  },
  methods: {
    _moment() {
      return moment;
    },
    onDoneConsultationCalled: function () {
      this.$vs.loading();
      this.$store.dispatch("agenda/UPDATE_EVENT", {
        state: "DONE",
        _id: this.$route.params.id,
        onData: ({ ok, data, message }) => {
          this.$vs.loading.close();
          this.consultation = data;

          this.$vs.notify({
            time: 4000,
            text: "Consultation terminée",
            color: ok ? "success" : "danger",
            iconPack: "feather",
            icon: "icon-check",
          });
        },
      });
    },
    async switchConsultationToVideo() {
      this.$vs.loading();
      await this.$store.dispatch("consultation/SWITCH_CONSULTATION_TO_VIDEO", {
        id: this.consultationID,
        onData: ({ ok }) => {
          if (ok) {
            this.consultation.type = "VIDEO-CONSULTATION";

            this.$nextTick(() => {
              this.$vs.loading.close();
            });
            return;
          }

          this.$vs.loading.close();
        },
      });
    },
    navFooterClick(type) {
      if (type === "next" && this.currentPage < 5) {
        this.currentPage++;
        this.$nextTick(() => {
          EventBus.$emit("CONSULTATION_TO_PAGE_CHANGE_NAV", this.currentPage);
        });
        return;
      }

      if (type === "back" && this.currentPage > 1) {
        this.currentPage--;
        this.$nextTick(() => {
          EventBus.$emit("CONSULTATION_TO_PAGE_CHANGE_NAV", this.currentPage);
        });
        return;
      }
    },
    getPatientInfo() {
      this.$store.dispatch("consultation/GET_USER", {
        id: this.consultationID,
        onData: ({ ok, data }) => {
          if (!ok) {
            console.log("GET PATIENT INFORMATION HAS ERROR");
            this.$router.go(-1);
            return;
          }
          this.$performTracking({
            data: {
              id: this.$auth.getUserId(),
              route: "CONSULTATION",
              action: "SHOW",
              data: { _id: data._id },
            },
          });
          this.patient = data;
          this.getConsultationInfo();
        },
      });
    },
    getConsultationInfo() {
      this.$store.dispatch("consultation/GET_BY_ID", {
        id: this.consultationID,
        onData: ({ ok, data }) => {
          if (!ok) {
            console.log("GET CONSULTATION INFORMATION HAS ERROR");
            this.$router.go(-1);
            return;
          }
          this.consultation = data;
          this.$nextTick(() => {
            this.loader = false;
            this.$nextTick(() => {
              this.hasLoadingConsultation = false;
              console.log("HAS LODING CONSUILTATION");
            });
          });
        },
      });
    },
    setPage(page) {
      this.$vs.loading();
      this.currentPage = page;
      this.$nextTick(() => {
        this.$vs.loading.close();
      });
    },
  },
};
</script>

<style lang="scss">
.scroll-nav {
  &::-webkit-scrollbar {
    height: 0px;
    width: 4px;
    border: 1px solid #d5d5d5;
  }
}

.scroll-dok {
  &::-webkit-scrollbar {
    width: 6px;
    height: 16px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 100vh;
    @apply bg-gray-200;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @apply bg-labelGray rounded;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-300;
  }
}

.blue-circle {
  animation-delay: 0.1s;
}

.green-circle {
  animation-delay: 0.2s;
}

.red-circle {
  animation-delay: 0.3s;
}
</style>
