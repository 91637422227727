<template>
  <div>
    <div
      class="bg-green-100 text-green-500 text-base font-EffraR w-full flex items-center gap-x-2 h-12 px-2 rounded-t-lg"
    >
      <div
        class="h-[26px] w-[26px] bg-green-500 text-white overflow-hidden rounded-full flex items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-eye"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
          <circle cx="12" cy="12" r="3"></circle>
        </svg>
      </div>
      Cette note sera partagée avec le patient
    </div>
    <div
      class="bg-white px-8 rounded-b-lg py-5 flex flex-col gap-y-6 w-full border-t-0"
      style="border: solid 2px #ededed;"
    >
      <div class="flex flex-row gap-x-1">
        <img src="/svg/consultation/file-text.svg" />
        <h1 class="text-xl font-EffraR text-black">Note du rendez-vous</h1>
      </div>

      <hr style="border: 1px solid #eaeaea;" />

      <div v-if="note" class="font-EffraR text-lg text-black">
        {{ note }}
      </div>

      <div
        v-if="!note"
        class="flex flex-row gap-x-1 font-EffraM text-lg"
        style="color: #8a8a8a;"
      >
        <img src="/svg/consultation/x_gray.svg" /> Pas de données
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      note: null
    };
  },
  methods: {
    ...mapActions({ getConsultaion: "consultation/GET_BY_ID" }),
    async getConsultationData() {
      await this.getConsultaion({
        id: this.$route.params.id,
        onData: ({ ok, data }) => {
          if (ok) {
            this.note = data.note;
          }
        }
      });
    }
  },
  created() {
    this.getConsultationData();
  }
};
</script>

<style></style>
