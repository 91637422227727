<template>
  <section id="Medicament">
    <div
      class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-10"
      :class="hasOpen ? 'min-h-20' : ' h-20'"
    >
      <!-- ---------------------------------------- Header ------------------------------------------------------------>
      <div class="flex justify-between items-center w-full">
        <div class="flex-1 flex flex-row gap-x-1">
          <img src="/svg/consultation/microscope-line.svg" />
          <h1 class="text-xl font-EffraR text-black">Analyses et bilans</h1>
        </div>

        <button
          class="bg-transparent border-0 p-0 m-0 cursor-pointer"
          @click="hasOpen = !hasOpen"
        >
          <img
            :src="
              hasOpen
                ? '/svg/consultation/chevron-up.svg'
                : '/svg/consultation/chevron-down.svg'
            "
          />
        </button>
      </div>
      <hr v-if="hasOpen" class="border border-solid border-gray-200" />
      <!-- ---------------------------------------- Content ----------------------------------------------------------->
      <div class="flex flex-col gap-y-6" v-if="hasOpen && !loader">
        <!-- ---------------------------------------- Favories -------------------------------------------------------->
        <section id="favs" class="bg-bgBlueTrans p-6 flex flex-col gap-y-4">
          <h1 class="text-base text-black font-EffraM">Favoris</h1>
          <template v-if="listFavories.length">
            <div class="grid gap-x-4 gap-y-2 grid-cols-1 lg:grid-cols-2">
              <div
                v-for="(fav, index) in listFavories"
                :key="index"
                class="
                  bg-white
                  rounded-cu
                  p-4
                  flex
                  items-center
                  justify-between
                  gap-x-2
                "
                style="border: solid 1px #eaf0ff"
              >
                <div
                  class="
                    h-8
                    w-8
                    rounded-full
                    cursor-pointer
                    hover:bg-dokBlue-lighter
                    relative
                    flex
                    items-center
                    justify-center
                  "
                  :class="
                    onExitsListSelected(fav.actbio._id) && 'bg-dokBlue-lighter'
                  "
                  style="border: solid 1px #eaeaea"
                  @click="setSelectItem(fav.actbio)"
                >
                  <i
                    class="fas fa-check text-dokBlue-ultra text-xl"
                    v-if="onExitsListSelected(fav.actbio._id)"
                  ></i>
                </div>

                <h1 class="font-EffraM text-base flex-1">
                  {{ fav.actbio.analyse }}
                  {{ fav.actbio.synonyme && `(${fav.actbio.synonyme})` }}
                </h1>

                <img src="/svg/consultation/star.svg" />
              </div>
            </div>
          </template>
        </section>

        <!-- ---------------------------------------- Biologie -------------------------------------------------------->
        <section id="Biologie" class="flex flex-col gap-y-2">
          <h1 class="font-EffraM text-2xl text-gray-600">Biologie</h1>
          <div
            class="
              grid
              gap-x-4 gap-y-3
              grid-cols-1
              lg:grid-cols-2
              items-baseline
            "
          >
            <div
              class="rounded p-6 flex flex-col gap-y-4"
              style="border: solid 1px #eaeaea"
              v-for="(item, index) in list['biologic']"
              :key="index"
            >
              <div class="flex justify-between items-center">
                <h1 class="font-EffraM text-black text-lg">{{ item._id }}</h1>
                <dok-button
                  bg="white"
                  custom-class="flex items-center gap-x-2"
                  @click.native="
                    openModal({ type: 'biologie', item: item._id })
                  "
                  >Voir plus <img src="/svg/consultation/trailing.svg"
                /></dok-button>
              </div>
              <div
                class="p-3 rounded"
                style="border: solid 1px #eaf0ff"
                v-for="(subItem, subIndex) in _().take(item.list, 3)"
                :key="subIndex"
              >
                <div class="flex justify-between items-center">
                  <div class="flex-1 flex flex-row items-center gap-x-2">
                    <div
                      class="
                        h-8
                        w-8
                        rounded-full
                        cursor-pointer
                        hover:bg-dokBlue-lighter
                        relative
                        flex
                        items-center
                        justify-center
                      "
                      :class="
                        onExitsListSelected(subItem._id) && 'bg-dokBlue-lighter'
                      "
                      style="border: solid 1px #eaeaea"
                      @click="setSelectItem(subItem)"
                    >
                      <i
                        class="fas fa-check text-dokBlue-ultra text-xl"
                        v-if="onExitsListSelected(subItem._id)"
                      ></i>
                    </div>

                    <h1 class="font-EffraM text-base">
                      {{ subItem.analyse }}
                      {{ subItem.synonyme && `(${subItem.synonyme})` }}
                    </h1>
                  </div>
                  <dok-button
                    bg="white"
                    @click.native="
                      onExitsFavorite('biologic', item._id, subItem.analyse)
                        ? deleteFavorite(
                            onExitsFavorite(
                              'biologic',
                              item._id,
                              subItem.analyse
                            )
                          )
                        : setAsFavorite(subItem)
                    "
                  >
                    <img
                      :src="
                        onExitsFavorite('biologic', item._id, subItem.analyse)
                          ? '/svg/consultation/star.svg'
                          : '/svg/consultation/lg_star_gray.svg'
                      "
                    />
                  </dok-button>
                </div>
              </div>

              <div
                v-if="false"
                class="flex items-center gap-x-2 font-EffraR text-sm"
                style="color: #767676"
              >
                <img src="/svg/consultation/star_gray.svg" /> Your saved items
                will be listed here
              </div>
            </div>
          </div>
        </section>

        <!-- ---------------------------------------- Anatomo-pathologie ---------------------------------------------->
        <section id="AnatomoPathologie" class="flex flex-col gap-y-2">
          <h1 class="font-EffraM text-2xl text-gray-600">Anatomo-pathologie</h1>
          <div
            class="
              grid
              gap-x-4 gap-y-3
              grid-cols-1
              lg:grid-cols-2
              items-baseline
            "
          >
            <div
              class="rounded p-6 flex flex-col gap-y-4"
              style="border: solid 1px #eaeaea"
              v-for="(item, index) in list['anatomy']"
              :key="index"
            >
              <div class="flex justify-between items-center">
                <h1 class="font-EffraM text-black text-lg">{{ item._id }}</h1>
                <dok-button
                  bg="white"
                  custom-class="flex items-center gap-x-2"
                  @click.native="openModal({ type: 'anatomy', item: item._id })"
                  >Voir plus <img src="/svg/consultation/trailing.svg"
                /></dok-button>
              </div>
              <div
                class="p-3 rounded"
                style="border: solid 1px #eaf0ff"
                v-for="(subItem, subIndex) in _().take(item.list, 3)"
                :key="subIndex"
              >
                <div class="flex justify-between items-center">
                  <div class="flex-1 flex flex-row items-center gap-x-2">
                    <div
                      class="
                        h-8
                        w-8
                        rounded-full
                        cursor-pointer
                        hover:bg-dokBlue-lighter
                        relative
                        flex
                        items-center
                        justify-center
                      "
                      :class="
                        onExitsListSelected(subItem._id) && 'bg-dokBlue-lighter'
                      "
                      style="border: solid 1px #eaeaea"
                      @click="setSelectItem(subItem)"
                    >
                      <i
                        class="fas fa-check text-dokBlue-ultra text-xl"
                        v-if="onExitsListSelected(subItem._id)"
                      ></i>
                    </div>
                    <h1 class="font-EffraM text-base">
                      {{ subItem.analyse }}
                      {{ subItem.synonyme && `(${subItem.synonyme})` }}
                    </h1>
                  </div>
                  <dok-button
                    bg="white"
                    @click.native="
                      onExitsFavorite('anatomy', item._id, subItem.analyse)
                        ? deleteFavorite(
                            onExitsFavorite(
                              'anatomy',
                              item._id,
                              subItem.analyse
                            )
                          )
                        : setAsFavorite(subItem)
                    "
                  >
                    <img
                      :src="
                        onExitsFavorite('anatomy', item._id, subItem.analyse)
                          ? '/svg/consultation/star.svg'
                          : '/svg/consultation/lg_star_gray.svg'
                      "
                    />
                  </dok-button>
                </div>
              </div>

              <div
                v-if="false"
                class="flex items-center gap-x-2 font-EffraR text-sm"
                style="color: #767676"
              >
                <img src="/svg/consultation/star_gray.svg" /> Your saved items
                will be listed here
              </div>
            </div>
          </div>
        </section>

        <!-- ------------------------------------------- Selected items ----------------------------------------------->
        <section id="selected" class="flex flex-col gap-y-3">
          <h1 class="font-EffraR text-base text-black leading-normal">
            Sélectionnés
          </h1>
          <div
            v-if="!listSelected.length"
            class="flex items-center gap-x-1 text-sm text-labelGray font-EffraM"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16"
            >
              <path
                stroke="#767676"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M14.667 7.386V8c0 1.437-.466 2.836-1.327 3.988-.86 1.15-2.07 1.993-3.45 2.4-1.378.409-2.851.36-4.2-.139-1.348-.498-2.5-1.419-3.282-2.625s-1.154-2.632-1.06-4.067c.095-1.434.65-2.8 1.585-3.893.934-1.093 2.196-1.854 3.598-2.171 1.403-.317 2.87-.172 4.183.413"
              />
              <path
                stroke="#767676"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14.667 2.667L8 9.34l-2-2"
              />
            </svg>

            Les analyses sélectionnées s'affichent ci-dessous
          </div>
          <div v-else class="grid grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-2">
            <div
              v-for="(item, index) in listSelected"
              :key="index"
              class="
                rounded
                p-3
                flex flex-row
                justify-between
                items-center
                gap-x-3
              "
              style="border: solid 1px #eaeaea"
            >
              <div class="flex-1 flex items-center gap-x-2">
                <div
                  class="
                    h-8
                    w-8
                    rounded-full
                    cursor-pointer
                    bg-dokBlue-lighter
                    relative
                    flex
                    items-center
                    justify-center
                  "
                  style="border: solid 1px #eaeaea"
                >
                  <i class="fas fa-check text-dokBlue-ultra text-xl"></i>
                </div>
                <h5 class="font-EffraM text-sm text-dokBlue-ultra">
                  {{ item.name }}
                </h5>
              </div>
              <span
                class="
                  p-1
                  bg-dokBlue-lighter
                  text-dokBlue-ultra
                  rounded
                  font-EffraM
                  text-sm
                "
                >{{
                  item.typeAnalyse === "anatomy"
                    ? "Anatomo-pathologie"
                    : "Biologie"
                }}</span
              >
            </div>
          </div>
        </section>

        <!-- ------------------------------------------------ Note ---------------------------------------------------->
        <div class="flex flex-col gap-y-1">
          <label for="spechText" class="text-base text-black font-EffraR">
            Notes (Optionnel)</label
          >
          <speach-textarea
            id="spechText"
            :content.sync="note"
            holder="Insert text here or dictate by clicking on the button below"
          ></speach-textarea>

          <p class="font-EffraR text-green-700 animate-pulse" v-if="saveNote">
            Enregistrement ...
          </p>
        </div>

        <!-- --------------------------------------------- Actions ---------------------------------------------------->
        <div class="flex flex-col lg:flex-row gap-y-3 justify-end gap-x-6">
          <dok-button
            size="lg"
            bg="bluelighter"
            custom-class="px-3 flex items-center gap-x-2"
            @click.native="openPrescriptionPreview('biologic')"
          >
            <img src="/svg/consultation/eye.svg" /> Aperçu d'analyse de biologie
          </dok-button>
          <dok-button
            size="lg"
            bg="bluelighter"
            custom-class="px-3 flex items-center gap-x-2"
            @click.native="openPrescriptionPreview('anatomo')"
          >
            <img src="/svg/consultation/eye.svg" /> Aperçu d'analyse
            d'anatomo-pathologie
          </dok-button>
          <dok-button
            size="lg"
            custom-class="px-3 flex items-center gap-x-2"
            @click.native="onGeneration"
          >
            <img src="/svg/consultation/sent.svg" /> Générer l'ordonnance
          </dok-button>
        </div>
      </div>
    </div>
    <!-- --------------------------------------------------- Modal ---------------------------------------------------->
    <modal
      :isActive="modal.modalIs"
      :exitModal="() => {}"
      :hiddenHeader="true"
      :hasNoScroll="true"
      :min-width="400"
    >
      <div class="p-5 flex flex-col gap-y-4">
        <div class="flex justify-between items-center">
          <h1 class="text-2xl font-EffraR text-black">
            {{ getItemModal._id }}
          </h1>
          <button
            class="bg-transparent border-0 m-0 p-0 cursor-pointer"
            @click="
              () => {
                modal.modalIs = false;
              }
            "
          >
            <img src="/svg/consultation/x.svg" />
          </button>
        </div>
        <dok-input
          size="lg"
          d-placeholder="Recherchez..."
          :d-model.sync="modal.tirmSerch"
        >
          <template v-slot:leftIcon>
            <img src="/svg/search.svg" alt="Search..." />
          </template>
        </dok-input>

        <div class="max-h-64 overflow-y-scroll flex flex-col gap-y-2">
          <div
            class="p-5 rounded flex items-center gap-x-2"
            style="border: solid 1px #eaeaea"
            v-for="(subItem, subIndex) in getItemModal.list"
            :key="subIndex"
          >
            <div class="flex justify-between items-center w-full">
              <div class="flex-1 flex flex-row items-center gap-x-2">
                <div
                  class="
                    h-8
                    w-8
                    rounded-full
                    cursor-pointer
                    hover:bg-dokBlue-lighter
                  "
                  style="border: solid 1px #eaeaea"
                ></div>
                <h1 class="font-EffraM text-base">
                  {{ subItem.analyse }}
                  {{ subItem.synonyme && `(${subItem.synonyme})` }}
                </h1>
              </div>
              <dok-button
                bg="white"
                @click.native="
                  onExitsFavorite(modal.type, getItemModal._id, subItem.analyse)
                    ? deleteFavorite(
                        onExitsFavorite(
                          modal.type,
                          getItemModal._id,
                          subItem.analyse
                        )
                      )
                    : setAsFavorite(subItem)
                "
              >
                <img
                  :src="
                    onExitsFavorite(
                      modal.type,
                      getItemModal._id,
                      subItem.analyse
                    )
                      ? '/svg/consultation/star.svg'
                      : '/svg/consultation/lg_star_gray.svg'
                  "
                />
              </dok-button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import _ from "lodash";
import speachTextarea from "@/views/global-components/spechToTextarea";
import modal from "@/views/global-components/modal";

export default {
  components: { speachTextarea, modal },
  data() {
    return {
      saveNote: false,
      hasOpen: false,
      note: "",
      list: [],
      tempList: [],
      listFavorite: [],
      listSelected: [],
      prescriptionID: null,
      loader: true,
      modal: {
        tirmSerch: null,
        type: null,
        item: null,
        modalIs: false,
      },
    };
  },
  watch: {
    "modal.tirmSerch"(newVal, oldVal) {
      if (newVal.length < 1) {
        this.getItemModal.list = this.tempList[this.modal.type].find(
          (item) => item._id === this.modal.item
        ).list;
      }
      if (newVal.length > 3) {
        let filterList = this.getItemModal.list.filter((item) => {
          return item.analyse.toLowerCase().indexOf(newVal.toLowerCase()) >= 0;
        });
        this.getItemModal.list = filterList;
      }
    },
    note: _.debounce(function (newVal, oldVal) {
      if (oldVal === null) {
        return;
      }
      return this.onSaveNote();
    }, 400),
  },
  computed: {
    getItemModal() {
      if (!this.modal.type) return [];
      return this.list[this.modal.type].find(
        (item) => item._id === this.modal.item
      );
    },
    listFavories() {
      return [
        ...this.listFavorite["anatomy"],
        ...this.listFavorite["biologic"],
      ];
    },
  },
  created() {
    this.getList();
  },
  methods: {
    _() {
      return _;
    },
    openModal(data) {
      this.modal.type = data.type === "biologie" ? "biologic" : "anatomy";
      this.modal.item = data.item;
      this.$nextTick(() => {
        this.modal.modalIs = true;
      });
    },
    onExitsFavorite(section, subSection, nameItem) {
      let i = _.find(this.listFavorite[section], (item) => {
        return (
          item.actbio.category === subSection &&
          item.actbio.analyse === nameItem
        );
      });
      return i ? i : false;
    },
    onExitsListSelected(refId) {
      return this.listSelected.find((item) => item.refId === refId)
        ? true
        : false;
    },
    openPrescriptionPreview(type) {
      window.open(
        `https://api.dok.ma/api/v1/prescription/${
          this.prescriptionID
        }/preview?type=biologic&typeAnalyse=${type}&token=${this.$auth.getToken()}`,
        "_blank"
      );
    },
    async getListFavorite() {
      await this.$store.dispatch("consultation/GET_MY_FAVORITE_ACTS", {
        onData: ({ data, ok }) => {
          if (ok) {
            this.listFavorite = data;
          }
          this.loader = false;
          this.$vs.loading.close();
        },
      });
    },
    async getList() {
      this.$vs.loading();
      await this.$store.dispatch("consultation/GET_STANDARD_ACTBIOS", {
        onData: ({ ok, data }) => {
          if (ok) {
            this.list = data;
            this.tempList = _.cloneDeep(data);
            this.listSelectedItem();
            this.getListFavorite();
          }
        },
      });
    },
    async listSelectedItem() {
      this.$store.dispatch("consultation/GET_PRESCRIPTIONS", {
        id: this.$route.params.id,
        category: "BIOLOGICAL",
        onData: ({ ok, data }) => {
          this.note = data.note;
          this.prescriptionID = data._id;
          if (ok) {
            this.$store.dispatch("consultation/GET_PRESCRIPTION_ITEMS", {
              id: data._id,
              onData: ({ ok: subOk, data: subData }) => {
                if (subOk) {
                  this.listSelected = subData;
                }
              },
            });
          }
        },
      });
    },
    async onGeneration() {
      this.$vs.loading();
      await this.$store.dispatch("consultation/GENERATION_DOCUMENT", {
        data: {
          id: this.prescriptionID,
          type: "biological",
        },
        onData: ({ ok, data, message }) => {
          this.$vs.notify({
            time: 4000,
            text: message,
            color: ok ? "success" : "danger",
          });
          this.$vs.loading.close();
        },
      });
    },
    async onSaveNote() {
      this.saveNote = true;
      this.$store.dispatch("consultation/SAVE_PRESCRIPTION_NOTE", {
        note: this.note,
        category: "BIOLOGICAL",
        id: this.$route.params.id,
        onData: ({ ok, message }) => {
          if (ok) {
            this.saveNote = false;
          }
        },
      });
    },
    async deleteSelectedItem(id) {
      this.$confirm({
        message: `Êtes-vous sûr(e)?`,
        button: {
          no: "Non",
          yes: "Oui",
        },
        callback: async (confirm) => {
          if (confirm) {
            await this.$store.dispatch("consultation/DELETE_PRESCRIPTIONS", {
              id: id,
              onData: ({ ok, message }) => {
                if (ok) {
                  this.listSelectedItem();
                }
              },
            });
          }
        },
      });
    },
    async setSelectItem(item) {
      const { name, refId, typeAnalyse } = {
        refId: item._id,
        name: `${item.analyse} ${item.synonyme && `(${item.synonyme})`}`,
        typeAnalyse: item.type,
      };
      let checkExits = this.listSelected.find((item) => item.refId === refId);
      if (checkExits) {
        this.deleteSelectedItem(checkExits._id);
        return;
      }
      this.$vs.loading();
      await this.$store.dispatch("consultation/CREATE_BIOLOGIQUE", {
        id: this.prescriptionID,
        data: {
          name,
          refId,
          typeAnalyse,
        },
        onData: ({ data: dataRes, ok: okRes }) => {
          if (okRes) {
            this.listSelectedItem();
          }
          this.$vs.loading.close();
        },
      });
    },
    async setAsFavorite(item) {
      this.$vs.loading();
      await this.$store.dispatch("consultation/ADD_ACTS_TO_FAVORITE", {
        data: {
          actbio: item._id,
        },
        onData: ({ ok, data }) => {
          this.getListFavorite();
        },
      });
    },
    async deleteFavorite(favorite) {
      this.$vs.loading();
      await this.$store.dispatch("consultation/REMOVE_ACTS_TO_FAVORITE", {
        favoriteId: favorite._id,
        onData: ({ ok, data }) => {
          this.getListFavorite();
        },
      });
    },
  },
};
</script>
