<template>
  <div class="flex flex-col gap-y-3">
    <!-- Video Call -->
    <video-call
      v-if="type === 'VIDEO-CONSULTATION'"
      :conversationIdHash="conversationIdHash"
      :conversationId="conversationId"
      :owner="patient"
      class="hidden lg:block"
    />
    <!-- Information Patient -->
    <div class="bg-white shadow rounded-cu p-5">
      <div class="flex flex-row items-center gap-x-3">
        <div class="relative">
          <span
            v-if="patient.identityVerified"
            class="
              absolute
              h-8
              w-8
              rounded-full
              bg-[#259ff2]
              shadow-lg
              p-0
              m-0
              z-50
              flex
              items-center
              justify-center
            "
            style="right: 0px; top: 0px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#FFF"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M20 6L9 17l-5-5"
              />
            </svg>
          </span>
          <div
            class="
              h-100
              w-100
              rounded-full
              relative
              overflow-hidden
              z-10
              border border-solid border-gray-100
            "
          >
            <img
              :src="$checkAvatar(patient.avatar)"
              class="h-full w-full object-cover"
            />
          </div>
        </div>
        <div class="flex flex-col gap-y">
          <h1 class="font-EffraM text-black text-lg">
            {{ `${patient.firstName} ${patient.lastName}` }}
          </h1>
          <span class="font-EffraR text-black text-base">
            {{ patient.birthday | moment("DD/MM/YYYY") }}
          </span>
          <span class="font-EffraR text-black text-base">
            {{ patient.CIN || "--" }}
          </span>
          <span class="font-EffraR text-black text-base">
            {{
              patient.landingPage &&
              patient.landingPage.address &&
              patient.landingPage.address.city
                ? `${patient.landingPage.address.city.name}`
                : null
            }}

            {{
              patient.landingPage && patient.landingPage.address.label
                ? ` ,${patient.landingPage.address.label}`
                : "--"
            }}
          </span>
        </div>
      </div>

      <div class="pt-3" v-if="!patient.identityVerified">
        <dok-button
          custom-class="w-full cursor-pointer"
          bg="blue"
          size="md"
          @click.native="onVerifiedProfile"
        >
          Confirmer l'identité du patient
        </dok-button>
      </div>
    </div>

    <template v-if="accessHelthbook === 'AUTHORIZED'">
      <!-- Helthbook Patient -->
      <div class="bg-white shadow rounded-cu p-5 flex flex-col gap-y-3">
        <div class="flex flex-row justify-between">
          <div class="flex-1 flex flex-row gap-x-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 30 30"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                d="M15 3.362l7.075 7.075c1.4 1.399 2.352 3.18 2.739 5.12.386 1.94.189 3.951-.568 5.779-.757 1.828-2.039 3.39-3.683 4.489-1.645 1.099-3.579 1.686-5.557 1.686-1.978 0-3.911-.587-5.556-1.686-1.645-1.1-2.926-2.661-3.683-4.489-.757-1.828-.954-3.839-.568-5.779s1.34-3.721 2.739-5.12L15 3.362z"
              />
            </svg>

            <span class="font-EffraM text-lg">Groupe Sanguin</span>
          </div>

          <span class="font-EffraR text-lg">
            {{ generalInfo.bloodType || "--" }}
          </span>
        </div>
        <div class="flex flex-row justify-between">
          <div class="flex-1 flex flex-row gap-x-3 items-center">
            <i class="fas fa-weight text-2xl"></i>
            <span class="font-EffraM text-lg">Poids</span>
          </div>

          <span class="font-EffraR text-lg">
            {{ generalInfo.weight ? `${generalInfo.weight} kg` : "--" }}
          </span>
        </div>
        <div class="flex flex-row justify-between">
          <div class="flex-1 flex flex-row gap-x-3 items-center">
            <i class="fas fa-text-height text-2xl"></i>
            <span class="font-EffraM text-lg">Taille</span>
          </div>

          <span class="font-EffraR text-lg">
            {{ generalInfo.height ? `${generalInfo.height} cm` : "--" }}
          </span>
        </div>
        <div class="flex flex-row justify-between">
          <div class="flex-1 flex flex-row gap-x-3 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                fill="#000"
                d="M19.779 8.221C17.7 6.144 14.939 5 12 5c-2.938 0-5.7 1.144-7.777 3.221-2.88 2.88-3.84 6.963-2.842 10.653.076.28.331.476.622.476h19.994c.291 0 .546-.195.622-.476.252-.932.38-1.9.38-2.875 0-2.938-1.144-5.7-3.221-7.778zm1.712 9.84H2.511C1.196 11.995 5.84 6.29 12 6.29c6.156 0 10.807 5.702 9.49 11.772z"
              />
              <path
                fill="#000"
                fill-rule="evenodd"
                d="M4.047 8.045C6.171 5.92 8.997 4.75 12.001 4.75c3.004 0 5.83 1.17 7.954 3.295 2.125 2.124 3.295 4.95 3.295 7.954 0 .997-.13 1.986-.389 2.94-.105.39-.459.661-.863.661H2.004c-.404 0-.758-.27-.863-.66-1.02-3.774-.04-7.95 2.906-10.895zm.354.353C6.43 6.368 9.13 5.25 12 5.25c2.872 0 5.57 1.118 7.6 3.148 2.031 2.03 3.149 4.73 3.149 7.6 0 .955-.125 1.9-.371 2.81-.047.173-.203.292-.38.292H2.003c-.178 0-.334-.12-.38-.291m0 0c-.976-3.607-.038-7.596 2.777-10.41l-2.777 10.41zm1.09-.998h18.574C22.428 11.971 17.93 6.54 12 6.54c-5.935 0-10.426 5.437-9.286 11.272zm-.447.303C.917 11.892 5.682 6.04 12 6.04c6.314 0 11.085 5.848 9.735 12.075l-.043.197H2.31l-.042-.197z"
                clip-rule="evenodd"
              />
              <path
                fill="#000"
                d="M12.001 10.512c.356 0 .645-.289.645-.645V8.652c0-.356-.289-.644-.645-.644-.355 0-.644.288-.644.644v1.215c0 .356.289.645.644.645z"
              />
              <path
                fill="#000"
                fill-rule="evenodd"
                d="M11.107 8.652c0-.494.4-.894.894-.894s.895.4.895.894v1.215c0 .494-.4.895-.895.895-.494 0-.894-.4-.894-.895V8.652zM12 8.258c-.217 0-.394.176-.394.394v1.215c0 .218.177.395.394.395.218 0 .395-.177.395-.395V8.652c0-.218-.177-.394-.395-.394z"
                clip-rule="evenodd"
              />
              <path
                fill="#000"
                d="M8.886 9.314c-.178-.308-.572-.414-.88-.236-.309.178-.414.572-.236.88l.607 1.053c.12.207.336.322.559.322.491 0 .806-.535.557-.966l-.607-1.053z"
              />
              <path
                fill="#000"
                fill-rule="evenodd"
                d="M7.88 8.862c.428-.248.975-.1 1.222.327l.608 1.053c.346.6-.092 1.341-.774 1.341-.309 0-.61-.16-.775-.447l-.608-1.052c-.247-.428-.1-.975.327-1.222zm.714 2.024c.073.126.205.197.342.197.3 0 .493-.328.34-.591L8.67 9.439c-.109-.189-.35-.253-.538-.144-.19.108-.254.35-.145.538m0 0l.608 1.053-.608-1.053z"
                clip-rule="evenodd"
              />
              <path
                fill="#000"
                d="M7.013 12.375l-1.052-.608c-.308-.178-.702-.072-.88.236-.178.308-.073.703.236.88 1.144.661 1.15.695 1.374.695.656 0 .892-.874.322-1.203z"
              />
              <path
                fill="#000"
                fill-rule="evenodd"
                d="M6.086 11.55l1.052.608c.793.458.463 1.67-.447 1.67-.063 0-.131-.002-.213-.024-.077-.021-.154-.056-.246-.104-.113-.06-.275-.155-.523-.3l-.517-.3c-.428-.247-.575-.794-.328-1.222m1.222-.327c-.428-.247-.975-.1-1.222.327l1.222-.327zm-.25.433c-.189-.11-.43-.045-.539.144-.109.189-.044.43.145.539l.536.312c.237.138.383.223.488.278.081.043.119.058.142.064.018.005.034.007.083.007.402 0 .545-.536.197-.736l-1.052-.608z"
                clip-rule="evenodd"
              />
              <path
                fill="#000"
                d="M15.358 9.314c.178-.308.572-.414.88-.236.309.178.415.572.237.88l-.608 1.053c-.12.207-.336.322-.559.322-.491 0-.806-.535-.557-.966l.607-1.053z"
              />
              <path
                fill="#000"
                fill-rule="evenodd"
                d="M16.364 8.862c-.428-.248-.975-.1-1.222.327l-.608 1.053c-.346.6.092 1.341.774 1.341.309 0 .61-.16.776-.447l.607-1.052c.247-.428.1-.975-.327-1.222zm-.714 2.024c-.072.126-.205.197-.342.197-.3 0-.493-.328-.34-.591l.607-1.053c.109-.189.35-.253.539-.144.188.108.253.35.144.538m0 0l-.608 1.053.608-1.053z"
                clip-rule="evenodd"
              />
              <path
                fill="#000"
                d="M17.23 12.375l1.053-.608c.308-.178.702-.072.88.236.178.308.073.703-.235.88-1.145.661-1.151.695-1.375.695-.656 0-.892-.874-.322-1.203z"
              />
              <path
                fill="#000"
                fill-rule="evenodd"
                d="M18.158 11.55l-1.052.608c-.793.458-.463 1.67.447 1.67.063 0 .132-.002.214-.024.076-.021.154-.056.245-.104.113-.06.275-.155.523-.3l.518-.3c.427-.247.574-.794.327-1.222m-1.222-.327c.428-.247.975-.1 1.222.327l-1.222-.327zm.25.433c.189-.11.43-.045.539.144.109.189.044.43-.144.539-.219.126-.394.229-.537.312-.236.138-.383.223-.487.278-.082.043-.12.058-.143.064-.017.005-.034.007-.082.007-.403 0-.545-.536-.198-.736l1.052-.608z"
                clip-rule="evenodd"
              />
              <path
                fill="#000"
                d="M5.87 15.354H4.654c-.356 0-.644.289-.644.645 0 .356.288.644.644.644H5.87c.355 0 .644-.288.644-.644 0-.356-.289-.645-.644-.645z"
              />
              <path
                fill="#000"
                fill-rule="evenodd"
                d="M3.76 15.999c0-.494.4-.895.894-.895H5.87c.494 0 .894.4.894.895 0 .494-.4.894-.894.894H4.654c-.494 0-.894-.4-.894-.894zm.894-.395c-.218 0-.394.177-.394.395 0 .218.176.394.394.394H5.87c.217 0 .394-.176.394-.394 0-.218-.177-.395-.394-.395H4.654z"
                clip-rule="evenodd"
              />
              <path
                fill="#000"
                d="M17.488 15.999c0 .356.289.644.645.644h1.215c.356 0 .645-.288.645-.644 0-.356-.289-.645-.645-.645h-1.215c-.356 0-.645.289-.645.645z"
              />
              <path
                fill="#000"
                fill-rule="evenodd"
                d="M17.238 15.999c0-.494.4-.895.895-.895h1.215c.494 0 .895.4.895.895 0 .494-.401.894-.895.894h-1.215c-.494 0-.895-.4-.895-.894zm.895-.395c-.218 0-.395.177-.395.395 0 .218.177.394.395.394h1.215c.218 0 .395-.176.395-.394 0-.218-.177-.395-.395-.395h-1.215z"
                clip-rule="evenodd"
              />
              <path
                fill="#000"
                d="M13.565 13.524l-2.02 2.02c-.407.406-.113 1.1.456 1.1.165 0 .33-.064.456-.19l2.02-2.019c.25-.251.25-.66 0-.911-.252-.252-.66-.252-.912 0z"
              />
              <path
                fill="#000"
                fill-rule="evenodd"
                d="M13.388 13.347c.35-.35.915-.35 1.265 0l-3.285 2.02.913-.913.684-.684m.423-.423l-2.02 2.02 2.02-2.02zm1.265 0c.349.35.349.916 0 1.265l-2.02 2.02c-.174.174-.403.261-.632.261-.788 0-1.2-.96-.633-1.527m2.931-1.665c-.154-.154-.404-.155-.558 0l-2.019 2.019c-.248.248-.07.673.279.673.101 0 .202-.038.279-.115l2.02-2.02c.153-.154.153-.403 0-.557z"
                clip-rule="evenodd"
              />
            </svg>

            <span class="font-EffraM text-lg">IMC</span>
          </div>

          <span class="font-EffraR text-lg">
            {{ generalInfo.imc ? `${generalInfo.imc}` : "--" }}
          </span>
        </div>
        <div class="flex flex-row justify-between">
          <div class="flex-1 flex flex-row gap-x-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <g fill="#000" clip-path="url(#clip0)">
                <path
                  d="M18.782 13.406c0 .389.314.703.703.703.388 0 .703-.314.703-.703 0-3.164-1.644-4.397-3.094-5.484-1.358-1.019-2.531-1.899-2.531-4.36 0-.388-.315-.703-.703-.703-.389 0-.704.315-.704.703 0 3.165 1.644 4.397 3.094 5.485 1.358 1.019 2.532 1.898 2.532 4.36z"
                />
                <path
                  fill-rule="evenodd"
                  d="M13.007 3.562c0-.47.382-.853.853-.853.47 0 .853.382.853.853 0 1.204.286 2.008.729 2.634.447.633 1.06 1.094 1.742 1.606l.007.005c.721.54 1.51 1.133 2.116 1.992.612.867 1.03 1.996 1.03 3.607 0 .471-.381.853-.852.853s-.854-.382-.854-.853c0-1.204-.285-2.008-.728-2.634-.447-.632-1.06-1.093-1.743-1.605l-.007-.005c-.72-.541-1.51-1.133-2.116-1.992-.611-.867-1.03-1.997-1.03-3.608zm.853-.553c-.306 0-.553.248-.553.553 0 1.554.402 2.622.975 3.435.577.817 1.331 1.384 2.058 1.93l.01.007c.672.504 1.321.99 1.798 1.665.483.684.784 1.55.784 2.807 0 .306.247.553.553.553.305 0 .553-.247.553-.553 0-1.553-.403-2.622-.976-3.434-.576-.818-1.33-1.385-2.058-1.93l-.01-.007c-.672-.505-1.32-.991-1.797-1.665-.484-.684-.784-1.55-.784-2.808 0-.305-.248-.553-.553-.553z"
                  clip-rule="evenodd"
                />
                <path
                  d="M20.188 3.562c0-.388-.315-.703-.703-.703-.389 0-.703.315-.703.703 0 3.026.81 4.24 1.524 5.312.662.994 1.288 1.933 1.288 4.532 0 .389.315.703.703.703.389 0 .703-.314.703-.703 0-3.025-.81-4.24-1.524-5.312-.662-.993-1.288-1.932-1.288-4.532z"
                />
                <path
                  fill-rule="evenodd"
                  d="M18.631 3.562c0-.47.383-.853.854-.853.47 0 .853.382.853.853 0 1.288.155 2.155.387 2.82.232.663.544 1.132.876 1.63l.002.002c.357.536.746 1.12 1.044 1.953.299.835.503 1.913.503 3.44 0 .47-.382.852-.853.852s-.853-.382-.853-.853c0-1.288-.155-2.155-.387-2.818-.232-.664-.545-1.133-.876-1.63l-.002-.003c-.357-.536-.746-1.12-1.044-1.953-.3-.835-.504-1.913-.504-3.44zm.854-.553c-.306 0-.553.248-.553.553 0 1.5.2 2.542.485 3.339.285.796.657 1.355 1.014 1.89l.002.004c.33.496.662.992.907 1.694.246.703.404 1.605.404 2.917 0 .306.248.553.553.553.306 0 .553-.247.553-.553 0-1.499-.2-2.541-.485-3.338-.285-.797-.657-1.355-1.014-1.89l-.003-.005c-.33-.495-.66-.991-.906-1.693-.246-.704-.404-1.606-.404-2.918 0-.305-.248-.553-.553-.553z"
                  clip-rule="evenodd"
                />
                <path
                  d="M23.297 15.516H.703c-.388 0-.703.314-.703.703v4.218c0 .389.315.704.703.704h22.594c.389 0 .703-.315.703-.703v-4.22c0-.388-.314-.702-.703-.702zm-3.515 1.406v2.812H9.89v-2.812h9.89zm-18.375 0h7.078v2.812H1.407v-2.812zm21.187 2.812h-1.406v-2.812h1.406v2.812z"
                />
                <path
                  fill-rule="evenodd"
                  d="M-.2 16.219c0-.499.405-.903.903-.903h22.594c.499 0 .903.404.903.903v4.218c0 .5-.404.904-.903.904H.703c-.498 0-.903-.405-.903-.904V16.22zm.903-.503c-.277 0-.503.225-.503.503v4.218c0 .278.226.504.503.504h22.594c.278 0 .503-.226.503-.503v-4.22c0-.277-.225-.502-.503-.502H.703zm.504 1.006h7.478v3.212H1.207v-3.212zm.4.4v2.412h6.678v-2.412H1.607zm8.084-.4h10.29v3.212H9.692v-3.212zm.4.4v2.412h9.49v-2.412h-9.49zm10.897-.4h1.806v3.212h-1.806v-3.212zm.4.4v2.412h1.006v-2.412h-1.006z"
                  clip-rule="evenodd"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <path fill="#fff" d="M0 0H24V24H0z" />
                </clipPath>
              </defs>
            </svg>

            <span class="font-EffraM text-lg">Fumeur</span>
          </div>

          <span class="font-EffraR text-lg">
            {{
              generalInfo.smoking && generalInfo.smoking.isSmoker
                ? "Oui"
                : "Non"
            }}
          </span>
        </div>
      </div>
      <!-- Vaccins Patient -->
      <div class="bg-white shadow rounded-cu p-5 flex flex-col gap-y-8">
        <div class="flex flex-row justify-between items-center">
          <div class="flex-1 font-EffraM text-lg flex items-center gap-x-2">
            <img src="/svg/consultation/syringe-line.svg" height="24" />
            Vaccins
          </div>
          <img
            src="/svg/chevron-down.svg"
            class="cursor-pointer"
            @click="
              () => {
                expand.vaccins = !expand.vaccins;
              }
            "
          />
        </div>
        <template v-if="expand.vaccins">
          <template v-if="!generalInfo.vaccins || !generalInfo.vaccins.length">
            <img src="/svg/null_data.svg" />
          </template>
          <template v-if="generalInfo.vaccins.length">
            <div
              class="
                flex flex-row
                justify-between
                gap-x-3
                border-0 border-b border-solid border-gray-200
                py-3
              "
              v-for="(vac, index) in generalInfo.vaccins"
              :key="index"
            >
              <div class="font-EffraM text-black text-base">{{ vac.name }}</div>
              <div class="font-EffraR text-graylabel">
                {{ moment()(vac.vaccinDate).format("DD/MM/YYYY") }}
              </div>
            </div>
          </template>
        </template>
      </div>
      <!-- Allergies et intolérences Patient -->
      <div class="bg-white shadow rounded-cu p-5 flex flex-col gap-y-8">
        <div class="flex flex-row justify-between items-center">
          <div class="flex-1 font-EffraM text-lg flex items-center gap-x-2">
            <img src="/svg/healthbook/lungs-line.svg" /> Allergies et
            intolérences
          </div>
          <img
            src="/svg/chevron-down.svg"
            class="cursor-pointer"
            @click="
              () => {
                expand.alergies = !expand.alergies;
              }
            "
          />
        </div>
        <template v-if="expand.alergies">
          <div
            class="
              flex flex-col
              pb-3
              border-0 border-b border-solid border-gray-200
            "
            v-for="(name, index) in alergiesList"
            :key="index"
          >
            <div class="flex flex-row gap-x-2 w-full">
              <div class="w-1/2">
                <h1 class="text-base font-EffraM text-black uppercase">
                  {{ name }} :
                </h1>
              </div>
              <div class="flex flex-col gap-y-1 w-1/2">
                <template v-if="!getItemAlergies(name).length">--</template>
                <template v-if="getItemAlergies(name).length">
                  <div
                    v-for="(ite, inx) in getItemAlergies(name)"
                    :key="inx"
                    class="font-EffraR text-base text-left w-full"
                  >
                    {{ ite.allergyName }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
      <!-- Antécedents Patient -->
      <div class="bg-white shadow rounded-cu p-5 flex flex-col gap-y-8">
        <div class="flex flex-row justify-between items-center">
          <div class="flex-1 font-EffraM text-lg flex items-center gap-x-2">
            <img src="/svg/healthbook/virus-line.svg" /> Antécedents
          </div>
          <img
            src="/svg/chevron-down.svg"
            class="cursor-pointer"
            @click="
              () => {
                expand.antecedents = !expand.antecedents;
              }
            "
          />
        </div>
        <template v-if="expand.antecedents">
          <div
            class="
              flex flex-col
              gap-y-4
              pb-3
              border-0 border-b border-solid border-gray-200
            "
          >
            <!---------------Familiaux------------------->
            <div
              class="
                flex flex-col
                gap-y-3
                border-0 border-b border-solid border-gray-200
                py-3
              "
              v-if="generalInfo.antecedentFamiliaux"
            >
              <h1 class="text-lg font-EffraR text-black flex-1">Familiaux :</h1>
              <template v-if="!generalInfo.antecedentFamiliaux.length"
                >--</template
              >
              <div
                class="flex flex-col gap-y-1"
                v-if="generalInfo.antecedentFamiliaux.length"
              >
                <span
                  class="
                    flex flex-col
                    gap-y-1
                    border border-solid border-gray-200
                    p-3
                    rounded
                  "
                  v-for="(fam, index) in generalInfo.antecedentFamiliaux"
                  :key="index"
                >
                  <span class="font-EffraM text-base text-black capitalize">
                    {{ fam.name }}
                  </span>
                  <span class="font-EffraR text-base text-graylabel">
                    {{ fam.moreInfo }}
                  </span>
                </span>
              </div>
            </div>
            <!---------------Chirurgicaux------------------->
            <div
              v-if="generalInfo.antecedentChirurgicaux"
              class="
                flex flex-col
                gap-y-3
                border-0 border-b border-solid border-gray-200
                py-3
              "
            >
              <h1 class="text-lg font-EffraR text-black flex-1">
                Chirurgicaux :
              </h1>
              <template v-if="!generalInfo.antecedentChirurgicaux.length"
                >--</template
              >
              <div
                class="flex flex-col gap-y-1"
                v-if="generalInfo.antecedentChirurgicaux.length"
              >
                <span
                  class="
                    flex flex-col
                    gap-y-1
                    border border-solid border-gray-200
                    p-3
                    rounded
                  "
                  v-for="(chir, index) in generalInfo.antecedentChirurgicaux"
                  :key="index"
                >
                  <span class="font-EffraR text-base text-black">
                    {{ chir.operation }}
                  </span>
                  <span class="font-EffraM text-base text-graylabel">
                    {{ moment()(chir.operationDate).format("DD/MM/YYYY") }}
                  </span>
                </span>
              </div>
            </div>
            <!---------------Médicaux------------------->
            <div
              class="flex flex-col gap-y-3 py-3"
              v-if="generalInfo.antecedentMedicaux"
            >
              <h1 class="text-lg font-EffraR text-black flex-1">Médicaux :</h1>
              <template v-if="!generalInfo.antecedentMedicaux.length"
                >--</template
              >
              <div
                class="flex flex-col gap-y-1"
                v-if="generalInfo.antecedentMedicaux.length"
              >
                <div
                  class="flex flex-col gap-y-0 bg-dokBlue-lighter p-5 rounded"
                  v-for="(med, index) in generalInfo.antecedentMedicaux"
                  :key="index"
                >
                  <span class="text-black font-EffraM">
                    {{ med.moreInfo }}
                  </span>
                  <div class="flex justify-between items-center gap-x-2">
                    <span class="text-graylabel font-EffraR">{{
                      med.name
                    }}</span>
                    <span class="text-sm font-EffraM text-graylabel">
                      {{
                        moment()(med.startTreatmentDate).format("DD/MM/YYYY")
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <!--Traitements chroniques Patient -->
      <div class="bg-white shadow rounded-cu p-5 flex flex-col gap-y-8">
        <div class="flex flex-row justify-between items-center">
          <div class="flex-1 font-EffraM text-lg flex items-center gap-x-2">
            <img src="/svg/healthbook/capsule-line.svg" />
            Traitements chroniques
          </div>
          <img
            src="/svg/chevron-down.svg"
            class="cursor-pointer"
            @click="
              () => {
                expand.traitments = !expand.traitments;
              }
            "
          />
        </div>
        <template v-if="expand.traitments">
          <!-- List Medicaments -->
          <div
            class="flex flex-col gap-y-3"
            v-if="generalInfo.medicaments.length"
          >
            <div
              class="grid grid-cols-1 gap-y-3 p-5 rounded-lg"
              style="border: solid 1px #ededed"
              v-for="(med, index) in generalInfo.medicaments"
              :key="index"
            >
              <div class="font-EffraM text-black text-base flex flex-col">
                <div class="font-EffraR text-black text-base">
                  Nom du Aller/complément
                </div>

                <span>
                  {{ med.name }}
                </span>
              </div>
              <div class="font-EffraM text-black text-base flex flex-col">
                <div class="font-EffraR text-black text-base">
                  Pour quelle pathologie?
                </div>

                <span>
                  {{ med.doses }}
                  {{
                    $medicamentDosageUnits[med.doseUnit]
                      ? $medicamentDosageUnits[med.doseUnit].unity
                      : ""
                  }}
                  {{ med.prises ? " - " + med.prises + " x/ " + "jours" : "" }}
                </span>
              </div>
              <div class="font-EffraM text-black text-base flex flex-col">
                <div class="font-EffraR text-black text-base">
                  Le dosage quotidien
                </div>

                <div class="flex justify-between">
                  <div class="flex-1">{{ med.moreInfo }}</div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="!generalInfo.medicaments.length">
            <img src="/svg/null_data.svg" />
          </template>
          <!-- End Of List Medicaments -->
        </template>
      </div>
    </template>

    <!-- historique Consultations -->
    <historique-consultations :patient="patient"></historique-consultations>

    <div
      v-if="accessHelthbook !== 'AUTHORIZED'"
      class="flex items-center gap-x-2 bg-white p-5 rounded-cu shadow"
    >
      <template v-if="accessHelthbook === 'PENDING'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          class="animate-spin"
        >
          <path
            stroke="#AE851A"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"
          />
        </svg>
        <span class="font-EffraM text-lg" style="color: #ae851a"
          >Demande d'accès en attente...</span
        >
      </template>
      <template v-if="accessHelthbook === 'REJECTED'">
        <dok-button
          size="lg"
          custom-class="px-10 flex items-center gap-x-2 hover:bg-opacity-80 group"
          @click.native="onSentRequestHelthbook"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            class="group-hover:animate-spin"
          >
            <path
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M23 4v6h-6M1 20v-6h6"
            />
            <path
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3.51 9c.507-1.433 1.37-2.715 2.505-3.725 1.137-1.01 2.51-1.715 3.993-2.05 1.483-.336 3.027-.29 4.487.132s2.79 1.208 3.865 2.283L23 10M1 14l4.64 4.36c1.075 1.075 2.404 1.86 3.865 2.283 1.46.423 3.004.468 4.487.133 1.482-.336 2.856-1.041 3.992-2.051 1.137-1.01 1.999-2.292 2.506-3.725"
            />
          </svg>

          Renvoyer une demande d'accès au carnet de santé...
        </dok-button>
      </template>
      <template v-if="!accessHelthbook">
        <div class="flex flex-col gap-y-6 items-center">
          <div
            class="
              flex flex-row
              gap-x-3
              items-center
              font-EffraM
              text-base text-black
              font-medium
            "
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="none"
                viewBox="0 0 26 26"
              >
                <circle cx="13" cy="13" r="13" fill="#EEE" />
                <g
                  stroke="#111"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  clip-path="url(#clip0)"
                >
                  <path
                    stroke-width="1.5"
                    d="M13 18c2.761 0 5-2.239 5-5s-2.239-5-5-5-5 2.239-5 5 2.239 5 5 5zM13 15v-2"
                  />
                  <path stroke-width="2" d="M13 11h.005" />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path
                      fill="#fff"
                      d="M0 0H12V12H0z"
                      transform="translate(7 7)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            Pour accéder aux informations du dossier médical, envoyer une
            demande au patient
          </div>
          <dok-button
            size="lg"
            custom-class="px-10 flex items-center gap-x-2"
            @click.native="onSentRequestHelthbook"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              fill="none"
              viewBox="0 0 25 24"
            >
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.5 3h6c1.06 0 2.078.421 2.828 1.172.75.75 1.172 1.767 1.172 2.828v14c0-.796-.316-1.559-.879-2.121C11.06 18.316 10.296 18 9.5 18h-7V3zM22.5 3h-6c-1.06 0-2.078.421-2.828 1.172-.75.75-1.172 1.767-1.172 2.828v14c0-.796.316-1.559.879-2.121.562-.563 1.325-.879 2.121-.879h7V3z"
              />
            </svg>

            Demande d'accès au carnet de santé
          </dok-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";
import videoCall from "@/views/global-components/video_call.vue";
import historiqueConsultations from "@/views/dashboard/consultationsN/globalComponents/historiqueConsultations.vue";
import moment from "moment";

export default {
  components: { videoCall, historiqueConsultations },
  props: {
    type: {
      type: String,
      required: true,
    },
    patient: {
      type: Object,
      required: true,
    },
    conversationIdHash: {
      type: String,
      required: true,
    },
    conversationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentVideoCall: true,
      generalInfo: {},
      accessHelthbook: null,
      expand: {
        vaccins: false,
        alergies: false,
        antecedents: false,
        traitments: false,
      },
      alergiesList: [
        "alimentaires",
        "medicamenteuses",
        "respiratoires",
        "de-contact",
      ],
    };
  },
  mounted() {
    EventBus.$on("EXPAND_VIDEO_CALL_DOK", () => {
      this.currentVideoCall = !this.currentVideoCall;
    });
  },
  created() {
    this.getResume();
    this.onCheckAccessHelthbook();
  },
  methods: {
    moment() {
      return moment;
    },
    ...mapActions({
      checkAuthorization: "healthbook/CHECK_AUTHORIZATION",
      requestAuthorization: "authorization/REQUEST_AUTHORIZATION",
    }),

    async onVerifiedProfile() {
      this.$vs.loading();

      await this.$store.dispatch("user/VERIFED_PATIENT_ACCOUNT", {
        patientId: this.patient._id,
        onData: ({ ok }) => {
          if (ok) {
            this.patient.identityVerified = true;
            this.$nextTick(() => {
              this.$vs.loading.close();
            });
            return;
          }
          this.$vs.loading.close();
        },
      });
    },
    async onSentRequestHelthbook() {
      this.$vs.loading();
      await this.requestAuthorization({
        patientId: this.patient._id,
        authScope: "HEALTH-BOOK",
        onData: ({ message, ok, data }) => {
          if (ok) {
            this.accessHelthbook = "PENDING";
          }

          EventBus.$emit("flashDok", {
            type: ok ? "success" : "error",
            message: message,
            timerAlert: 5000,
          });

          this.$vs.loading.close();
        },
      });
    },
    async onCheckAccessHelthbook() {
      if (!this.patient._id) {
        return console.log("Can't be load healthbook");
      }
      await this.checkAuthorization({
        patientId: this.patient._id,
        onData: ({ ok, data }) => {
          console.log(data, ok);
          if (ok) {
            this.accessHelthbook = data;
          }
        },
      });
    },
    getItemAlergies(name) {
      if (name === "alimentaires") {
        return this.generalInfo.allergies &&
          this.generalInfo.allergies.alimentaires &&
          this.generalInfo.allergies.alimentaires.allergies
          ? this.generalInfo.allergies.alimentaires.allergies
          : [];
      }

      if (name === "medicamenteuses") {
        return this.generalInfo.allergies &&
          this.generalInfo.allergies.medicamenteuses &&
          this.generalInfo.allergies.medicamenteuses.allergies
          ? this.generalInfo.allergies.medicamenteuses.allergies
          : [];
      }

      if (name === "respiratoires") {
        return this.generalInfo.allergies &&
          this.generalInfo.allergies.respiratoires &&
          this.generalInfo.allergies.respiratoires.allergies
          ? this.generalInfo.allergies.respiratoires.allergies
          : [];
      }

      if (name === "de-contact") {
        return this.generalInfo.allergies &&
          this.generalInfo.allergies["de-contact"] &&
          this.generalInfo.allergies["de-contact"].allergies
          ? this.generalInfo.allergies["de-contact"].allergies
          : [];
      }

      return [];
    },
    async getResume() {
      const { data, ok } = await this.$store.dispatch(
        "healthbook/GET_PATIENT_HEALT_BOOK",
        {
          patientId: this.patient._id,
        }
      );

      if (ok) {
        this.generalInfo = data;

        if (!data.smoking) {
          this.generalInfo.smoking = { isSmoker: false };
        }

        this.$nextTick(() => {
          this.$vs.loading.close();
        });
      }
    },
  },
};
</script>

<style></style>
