<template>
  <div
    class="
      border border-solid border-otgray
      rounded-lg
      p-8
      grid
      gap-x-3 gap-y-2
      grid-cols-1
      md:grid-cols-3
      lg:grid-cols-5
    "
  >
    <div class="flex flex-col gap-y-1">
      <span class="text-sm font-EffraR" style="color: #767676"
        >Nom/categorie</span
      >
      <span class="text-base text-black font-EffraM truncate">
        {{ item.name }}
      </span>
    </div>
    <div class="flex flex-col gap-y-1">
      <span class="text-sm font-EffraR" style="color: #767676">Date</span>
      <span class="text-base text-black font-EffraM">
        {{ item.created_at | moment("DD/MM/YYYY") }} à
        {{ item.created_at | moment("HH:mm") }}
      </span>
    </div>
    <div class="flex flex-col gap-y-1">
      <span class="text-sm font-EffraR" style="color: #767676">Auteur</span>
      <span class="text-base text-black font-EffraM">
        {{ `${item.owner.firstName} ${item.owner.lastName}` }}
      </span>
    </div>
    <div class="flex flex-col gap-y-1">
      <span class="text-sm font-EffraR" style="color: #767676">Tags</span>
      <div class="flex flex-wrap items-center gap-x-2 gap-y-2">
        <span
          class="bg-otgray py-1 px-3 rounded-cu text-black font-EffraM text-sm"
          v-for="(tag, index) in item.tags"
          :key="index"
        >
          {{ tag }}
        </span>

        <span class="font-EffraM" v-if="!item.tags || !item.tags.length"
          >--</span
        >
      </div>
    </div>
    <div class="flex flex-row justify-end gap-x-2">
      <button
        class="
          border-0
          bg-transparent
          hover:bg-gray-200
          h-[50px]
          w-[50px]
          cursor-pointer
          rounded-full
        "
        data-tooltip="Voir"
        aria-label="Voir"
        title="Voir"
        @click="readFile(item)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="#111"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
          />
          <path
            stroke="#111"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 15c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
          />
        </svg>
      </button>
      <button
        class="
          border-0
          bg-transparent
          hover:bg-gray-200
          h-[50px]
          w-[50px]
          cursor-pointer
          rounded-full
        "
        data-tooltip="Supprimer"
        aria-label="Supprimer"
        title="Supprimer"
        @click="emitDeleteFile(item)"
      >
        <svg
          class="stroke-current text-red-600 group-focus:text-dokBlue-ultra"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 6h18M8 6V4c0-.53.21-1.04.586-1.414C8.96 2.21 9.47 2 10 2h4c.53 0 1.04.21 1.414.586C15.79 2.96 16 3.47 16 4v2m3 0v14c0 .53-.21 1.04-.586 1.414C18.04 21.79 17.53 22 17 22H7c-.53 0-1.04-.21-1.414-.586C5.21 21.04 5 20.53 5 20V6h14z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    readFile(item) {
      window.open(
        `https://api.dok.ma/api/v1/download/document/${item.metadata.uid}`,
        "_blank"
      );
    },
    emitDeleteFile(item) {
      EventBus.$emit("flashDok", {
        defualtTime: 30000,
        type: "delete",
        title: "Suppression du document",
        message: "Êtes-vous sûrs de vouloir supprimer ce document ?.",
        successmessage: "Rendez-vous supprimé avec succès",
        confirm: () => {
          return EventBus.$emit("ON_DELETE_DOCUMENT_UPLOAD", item);
        },
        cancel: (callback) => {
          return callback();
        },
      });
    },
  },
};
</script>
