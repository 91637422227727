<template>
  <div>
    <template v-if="!loader">
      <div class="flex flex-col gap-y-3">
        <dok-symptomes :consultationID="consultation._id"></dok-symptomes>
        <dok-exploration :consultationID="consultation._id"></dok-exploration>
        <dok-diagnostic :consultationID="consultation._id"></dok-diagnostic>
        <dok-note-confidentielle
          :consultationID="consultation._id"
        ></dok-note-confidentielle>
        <dok-medicaments :consultationID="consultation._id"></dok-medicaments>
        <dok-analyses-bilans
          :consultationID="consultation._id"
        ></dok-analyses-bilans>
        <dok-imageries :consultationID="consultation._id"></dok-imageries>
        <dok-actes :consultationID="consultation._id"></dok-actes>
        <!----------------------- Suivi ---------------------->
        <dok-suivi-medical></dok-suivi-medical>
      </div>
    </template>

    <template v-if="loader">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; display: block; shape-rendering: auto"
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="30" cy="50" fill="#fd5b68" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="-0.5s"
          ></animate>
        </circle>
        <circle cx="70" cy="50" fill="#2e6bff" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="0s"
          ></animate>
        </circle>
        <circle cx="30" cy="50" fill="#fd5b68" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="-0.5s"
          ></animate>
          <animate
            attributeName="fill-opacity"
            values="0;0;1;1"
            calcMode="discrete"
            keyTimes="0;0.499;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
        </circle>
      </svg>
    </template>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  import dokSymptomes from "./layouts/symptomes.vue";
  import dokExploration from "./layouts/exploration.vue";
  import dokDiagnostic from "./layouts/diagnostic.vue";
  import dokNoteConfidentielle from "./layouts/note_confidentielle.vue";
  import dokMedicaments from "./layouts/medicaments.vue";
  import dokAnalysesBilans from "./layouts/analyses_bilans.vue";
  import dokActes from "./layouts/actes.vue";
  import dokImageries from "./layouts/imageries.vue";
  import dokSuiviMedical from "./layouts/suivi_medical.vue";

  export default {
    components: {
      dokSymptomes,
      dokExploration,
      dokDiagnostic,
      dokNoteConfidentielle,
      dokMedicaments,
      dokAnalysesBilans,
      dokActes,
      dokImageries,
      dokSuiviMedical,
    },
    data() {
      return {
        consultation: {},
        loader: true,
      };
    },
    created() {
      this.getConsultaion({
        id: this.$route.params.id,
        onData: ({ data, ok }) => {
          if (ok) {
            this.consultation = data;
            this.$nextTick(() => {
              this.loader = false;
            });
          }
        },
      });
    },
    methods: {
      ...mapActions({
        getConsultaion: "consultation/GET_BY_ID",
      }),
    },
  };
</script>

<style></style>
